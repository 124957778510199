import { composeSavedFilterText } from './composeSavedFilterText';
export const convertLeafToSavedFilter = (leaf) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11;
    const savedFilter = {
        code: leaf.id,
        text: composeSavedFilterText(leaf),
        rawConditions: {
            notNamePostfix: !!leaf.notNamePostfix,
            type: (_a = leaf.value) === null || _a === void 0 ? void 0 : _a.type,
            op: (leaf.template === 'checkboxes-segments' && leaf.useBothOperations) ||
                leaf.template === 'counter'
                ? 'include'
                : ((_b = leaf.value) === null || _b === void 0 ? void 0 : _b.operator) || 'include',
        },
    };
    if (leaf.code) {
        savedFilter.code = leaf.code;
        savedFilter.id = leaf.id;
    }
    if (leaf.extra)
        savedFilter.rawConditions = Object.assign(Object.assign({}, savedFilter.rawConditions), leaf.extra);
    if (leaf.template === 'radios')
        savedFilter.rawConditions.radios = ((_d = (_c = leaf.value) === null || _c === void 0 ? void 0 : _c.value) === null || _d === void 0 ? void 0 : _d.toString()) || '';
    if (leaf.template === 'checkboxes-simple' ||
        leaf.template === 'checkboxes-loader' ||
        leaf.template === 'checkboxes-paginated-loader')
        savedFilter.rawConditions.checkboxes = (_f = (_e = leaf.value) === null || _e === void 0 ? void 0 : _e.value) === null || _f === void 0 ? void 0 : _f.reduce((prev, curr) => (Object.assign(Object.assign({}, prev), { [curr]: true })), {});
    if (leaf.template === 'checkboxes-tree-loader') {
        savedFilter.rawConditions.branchCheckboxes =
            (_j = (_h = (_g = leaf.value) === null || _g === void 0 ? void 0 : _g.value) === null || _h === void 0 ? void 0 : _h.branches) === null || _j === void 0 ? void 0 : _j.reduce((prev, curr) => (Object.assign(Object.assign({}, prev), { [curr]: true })), {});
        savedFilter.rawConditions.leafCheckboxes =
            (_m = (_l = (_k = leaf.value) === null || _k === void 0 ? void 0 : _k.value) === null || _l === void 0 ? void 0 : _l.leaves) === null || _m === void 0 ? void 0 : _m.reduce((prev, curr) => (Object.assign(Object.assign({}, prev), { [curr]: true })), {});
        savedFilter.rawConditions.branchPrefix = leaf.branchPrefix;
        savedFilter.rawConditions.leafPrefix = leaf.leafPrefix;
    }
    if (leaf.template === 'checkboxes-segments' && !leaf.useBothOperations)
        savedFilter.rawConditions.checkboxes = (_p = (_o = leaf.value) === null || _o === void 0 ? void 0 : _o.value) === null || _p === void 0 ? void 0 : _p.reduce((prev, curr) => (Object.assign(Object.assign({}, prev), { [curr]: true })), {});
    if (leaf.template === 'checkboxes-segments' && leaf.useBothOperations) {
        savedFilter.rawConditions.op = 'include';
        savedFilter.rawConditions.checkboxes = (_r = (_q = leaf.value) === null || _q === void 0 ? void 0 : _q.includeValues) === null || _r === void 0 ? void 0 : _r.reduce((prev, curr) => (Object.assign(Object.assign({}, prev), { [curr]: true })), {});
        if ((_s = leaf.value) === null || _s === void 0 ? void 0 : _s.excludeValues) {
            savedFilter.rawConditions.not = { op: 'exclude' };
            savedFilter.rawConditions.not.checkboxes =
                (_t = leaf.value) === null || _t === void 0 ? void 0 : _t.excludeValues.reduce((prev, curr) => (Object.assign(Object.assign({}, prev), { [curr]: true })), {});
        }
    }
    if (leaf.template === 'checkboxes-loader' ||
        leaf.template === 'checkboxes-paginated-loader' ||
        leaf.template === 'checkboxes-tree-loader' ||
        (leaf.template === 'checkboxes-segments' && !leaf.useBothOperations))
        savedFilter.rawConditions.searchText = (_u = leaf.value) === null || _u === void 0 ? void 0 : _u.search;
    if (leaf.template === 'numRange') {
        savedFilter.rawConditions.from = (_v = leaf.value) === null || _v === void 0 ? void 0 : _v.min;
        savedFilter.rawConditions.to = (_w = leaf.value) === null || _w === void 0 ? void 0 : _w.max;
        if (savedFilter.code.startsWith('counter_'))
            savedFilter.rawConditions.offerId = (_x = leaf.extra) === null || _x === void 0 ? void 0 : _x.offerId;
    }
    if (leaf.template === 'birthdateDays') {
        savedFilter.rawConditions.before = (_y = leaf.value) === null || _y === void 0 ? void 0 : _y.before;
        savedFilter.rawConditions.after = (_z = leaf.value) === null || _z === void 0 ? void 0 : _z.after;
    }
    if (leaf.template === 'textField')
        savedFilter.rawConditions.textValue = (_0 = leaf.value) === null || _0 === void 0 ? void 0 : _0.value;
    if (leaf.template === 'date') {
        savedFilter.rawConditions.day = (_1 = leaf.value) === null || _1 === void 0 ? void 0 : _1.day;
        savedFilter.rawConditions.month = (_2 = leaf.value) === null || _2 === void 0 ? void 0 : _2.month;
        savedFilter.rawConditions.year = (_3 = leaf.value) === null || _3 === void 0 ? void 0 : _3.year;
    }
    if (leaf.template === 'dateRange') {
        savedFilter.rawConditions.dateFrom = (_4 = leaf.value) === null || _4 === void 0 ? void 0 : _4.dateFrom;
        savedFilter.rawConditions.dateTo = (_5 = leaf.value) === null || _5 === void 0 ? void 0 : _5.dateTo;
    }
    if (leaf.template === 'counter') {
        savedFilter.rawConditions.name = (_6 = leaf.value) === null || _6 === void 0 ? void 0 : _6.name;
        savedFilter.rawConditions.requiredValue = (_7 = leaf.value) === null || _7 === void 0 ? void 0 : _7.requiredValue;
        savedFilter.rawConditions.applyOn = (_8 = leaf.value) === null || _8 === void 0 ? void 0 : _8.applyOn;
        savedFilter.rawConditions.periodType = (_9 = leaf.value) === null || _9 === void 0 ? void 0 : _9.periodType;
        savedFilter.rawConditions.periodValue = (_10 = leaf.value) === null || _10 === void 0 ? void 0 : _10.periodValue;
        savedFilter.rawConditions.periodUnit = (_11 = leaf.value) === null || _11 === void 0 ? void 0 : _11.periodUnit;
    }
    return savedFilter;
};
