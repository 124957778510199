import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/Button2/Button';
import { Dropdown } from '@/components/Dropdown2/Dropdown';
import { Arrow } from '@/icons/Arrow';
import { EmptyControl } from './EmptyControl';
import styles from './Wrapper.module.scss';
export const Wrapper = memo(({ isLoading = false, isOverflow = false, isFullHeight = false, isHeaderVisible = true, isApplyButtonDisabled = false, label, operator, count, applyButtonForm, excludeCount, children, additionalSelectorOptions, additionalSelectorTitle, onBack, onChangeOperator, onApplyClick, }) => {
    const { t } = useTranslation();
    let applyCount = count;
    let applyExcludeCount = excludeCount;
    if (excludeCount && !count) {
        applyCount = excludeCount;
        applyExcludeCount = 0;
    }
    let applyButtonLabel = t('Применить');
    if (applyCount && applyExcludeCount)
        applyButtonLabel = `${t('Применить')} (${applyCount}, ${t('кроме')} ${applyExcludeCount})`;
    if (applyCount && !applyExcludeCount)
        applyButtonLabel = `${t('Применить')} (${applyCount})`;
    return (_jsxs("div", Object.assign({ className: styles.wrapper }, { children: [onBack && (_jsxs("div", Object.assign({ className: styles.header }, { children: [_jsx(Button, Object.assign({ isOutlined: true, onClick: onBack, className: styles.backButton, icon: _jsx(Arrow, {}) }, { children: t('Назад') })), _jsx("span", Object.assign({ className: styles.selectedCodeLabel }, { children: label }))] }))), _jsx("div", Object.assign({ className: classNames(styles.content, {
                    [styles.overflowContent]: isOverflow,
                }) }, { children: isLoading ? (_jsx(EmptyControl, { isLoading: true })) : (_jsxs(_Fragment, { children: [isHeaderVisible && (_jsx("div", Object.assign({ className: styles.operator }, { children: _jsx(Dropdown, { title: operator === 'include' ? t('Включить') : t('Исключить'), items: [
                                    {
                                        id: 'include',
                                        label: t('Включить'),
                                        onClick: () => onChangeOperator === null || onChangeOperator === void 0 ? void 0 : onChangeOperator('include'),
                                    },
                                    {
                                        id: 'exclude',
                                        label: t('Исключить'),
                                        onClick: () => onChangeOperator === null || onChangeOperator === void 0 ? void 0 : onChangeOperator('exclude'),
                                        isDanger: true,
                                    },
                                ], size: 'normal', isDanger: operator === 'exclude', disablePortal: true }) }))), additionalSelectorOptions && (_jsx("div", Object.assign({ className: styles.additionalSelectorOptions }, { children: _jsx(Dropdown, { title: additionalSelectorTitle, items: additionalSelectorOptions, size: 'normal', disablePortal: true }) }))), _jsx("div", Object.assign({ className: classNames(styles.control, {
                                [styles.fullHeight]: isFullHeight,
                            }) }, { children: children }))] })) })), _jsx("div", Object.assign({ className: styles.footer }, { children: _jsx(Button, Object.assign({ theme: 'primary', disabled: isLoading || isApplyButtonDisabled, onClick: onApplyClick, size: 'full-width', form: applyButtonForm, type: applyButtonForm ? 'submit' : undefined }, { children: applyButtonLabel })) }))] })));
});
