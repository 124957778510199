const convertCheckboxesToArray = (checkboxes) => {
    return Object.entries(checkboxes || {})
        .filter(([, checked]) => checked)
        .map(([id]) => id);
};
export const injectSavedFilterToLeaf = (leaf, savedFilter) => {
    var _a;
    const operator = savedFilter.rawConditions.op;
    const search = savedFilter.rawConditions.searchText;
    leaf.text = savedFilter.text;
    if (savedFilter.id) {
        leaf.id = savedFilter.id;
        leaf.code = savedFilter.code;
    }
    if (leaf.template === 'radios')
        leaf.value = {
            operator,
            value: Number(savedFilter.rawConditions.radios),
        };
    if (leaf.template === 'checkboxes-simple' ||
        leaf.template === 'checkboxes-loader' ||
        leaf.template === 'checkboxes-paginated-loader' ||
        (leaf.template === 'checkboxes-segments' && !leaf.useBothOperations))
        leaf.value = {
            operator,
            search,
            type: savedFilter.rawConditions.type,
            value: convertCheckboxesToArray(savedFilter.rawConditions.checkboxes),
        };
    if (leaf.template === 'checkboxes-tree-loader')
        leaf.value = {
            operator,
            search,
            value: {
                branches: convertCheckboxesToArray(savedFilter.rawConditions.branchCheckboxes),
                leaves: convertCheckboxesToArray(savedFilter.rawConditions.leafCheckboxes),
            },
        };
    if (leaf.template === 'checkboxes-segments' && leaf.useBothOperations)
        leaf.value = {
            includeValues: convertCheckboxesToArray(savedFilter.rawConditions.checkboxes),
            excludeValues: convertCheckboxesToArray((_a = savedFilter.rawConditions.not) === null || _a === void 0 ? void 0 : _a.checkboxes),
        };
    if (leaf.template === 'numRange')
        leaf.value = {
            operator,
            min: savedFilter.rawConditions.from,
            max: savedFilter.rawConditions.to,
        };
    if (leaf.template === 'birthdateDays')
        leaf.value = {
            operator,
            before: savedFilter.rawConditions.before,
            after: savedFilter.rawConditions.after,
        };
    if (leaf.template === 'textField')
        leaf.value = {
            operator,
            value: savedFilter.rawConditions.textValue,
        };
    if (leaf.template === 'date')
        leaf.value = {
            operator,
            day: savedFilter.rawConditions.day,
            month: savedFilter.rawConditions.month,
            year: savedFilter.rawConditions.year,
        };
    if (leaf.template === 'dateRange')
        leaf.value = {
            operator,
            dateFrom: savedFilter.rawConditions.dateFrom,
            dateTo: savedFilter.rawConditions.dateTo,
        };
    if (leaf.template === 'counter')
        leaf.value = {
            name: savedFilter.rawConditions.name,
            requiredValue: savedFilter.rawConditions.requiredValue,
            applyOn: savedFilter.rawConditions.applyOn,
            periodType: savedFilter.rawConditions.periodType,
            periodValue: savedFilter.rawConditions.periodValue,
            periodUnit: savedFilter.rawConditions.periodUnit,
        };
};
