import { jsx as _jsx } from "react/jsx-runtime";
import { createHref, ROUTES } from '@/app/pages/routes';
import { Analytics } from '@/icons/nav/Analytics';
import { AnalyticsSolid } from '@/icons/nav/AnalyticsSolid';
import { Billing } from '@/icons/nav/Billing';
import { BillingSolid } from '@/icons/nav/BillingSolid';
import { Clients } from '@/icons/nav/Clients';
import { ClientsSolid } from '@/icons/nav/ClientsSolid';
import { Flows } from '@/icons/nav/Flows';
import { FlowsSolid } from '@/icons/nav/FlowsSolid';
import { Instructions } from '@/icons/nav/Instructions';
import { InstructionsSolid } from '@/icons/nav/InstructionsSolid';
import { Mailings } from '@/icons/nav/Mailings';
import { MailingsSolid } from '@/icons/nav/MailingsSolid';
import { Settings } from '@/icons/nav/Settings';
import { SettingsSolid } from '@/icons/nav/SettingsSolid';
import { Tools } from '@/icons/nav/Tools';
import { ToolsSolid } from '@/icons/nav/ToolsSolid';
import { brandHasFlowFeature } from '@/utils/brandHasFlowFeature';
import { brandHasRFMFeature } from '@/utils/brandHasRFMFeature';
import { brandHasWalletFeature } from '@/utils/brandHasWalletFeature';
export const buildAppNav = (isCollapsed, brand, userHasAccessTo) => {
    const isRFMEnabled = brandHasRFMFeature(brand);
    const isFlowsEnabled = brandHasFlowFeature(brand);
    const hasOffersSupport = brand.features.offers;
    const hasGiftCardsSupport = brand.features.giftCards;
    const hasWalletCardsSupport = brandHasWalletFeature(brand);
    const hasInn = !!brand.settings.inn;
    const iconSize = isCollapsed ? 22 : 20;
    return [
        [
            {
                id: 'analytics',
                name: 'Аналитика',
                icon: _jsx(Analytics, { size: iconSize }),
                iconAccent: _jsx(AnalyticsSolid, { size: iconSize }),
                shouldDisplay: userHasAccessTo([
                    'DASHBOARD_VIEW',
                    'SMARTRFM_VIEW',
                    'REPORTS_MANAGE',
                    'REPORTS_VIEW',
                    'EXPORTS_MANAGE',
                ]),
                items: [
                    {
                        name: 'Сводка',
                        route: ROUTES.DASHBOARD,
                        href: createHref(ROUTES.DASHBOARD),
                        shouldDisplay: userHasAccessTo('DASHBOARD_VIEW'),
                    },
                    {
                        name: 'Smart RFM',
                        href: createHref(ROUTES.RFM),
                        route: ROUTES.RFM,
                        displayAsBlocked: !isRFMEnabled,
                        tooltip: isRFMEnabled
                            ? undefined
                            : 'Для настройки данного модуля обратитесь к менеджеру MAXMA',
                        shouldDisplay: userHasAccessTo('SMARTRFM_VIEW'),
                    },
                    {
                        name: 'Отчеты',
                        route: ROUTES.REPORTS,
                        href: createHref(ROUTES.REPORTS),
                        shouldDisplay: userHasAccessTo(['REPORTS_MANAGE', 'REPORTS_VIEW']),
                    },
                    {
                        name: 'Выгрузки',
                        route: ROUTES.EXPORTS,
                        href: createHref(ROUTES.EXPORTS),
                        shouldDisplay: userHasAccessTo('EXPORTS_MANAGE'),
                    },
                ],
            },
            {
                id: 'clients',
                name: 'Клиенты',
                shouldDisplay: userHasAccessTo([
                    'CLIENTS_LIST_OWN',
                    'CLIENTS_LIST',
                    'CLIENTS_MANAGE_OWN',
                    'CLIENTS_MANAGE',
                    'CLIENTS_BONUSES_MANAGE',
                    'CLIENTS_SEGMENTS_MANAGE',
                    'CLIENTS_PURCHASES_LIST',
                    'CLIENTS_PURCHASES_MANAGE',
                ]),
                icon: _jsx(Clients, { size: iconSize }),
                iconAccent: _jsx(ClientsSolid, { size: iconSize }),
                items: [
                    {
                        name: 'Список клиентов',
                        route: ROUTES.CLIENTS,
                        href: createHref(ROUTES.CLIENTS),
                        shouldDisplay: userHasAccessTo([
                            'CLIENTS_LIST_OWN',
                            'CLIENTS_LIST',
                            'CLIENTS_MANAGE_OWN',
                            'CLIENTS_MANAGE',
                        ]),
                    },
                    {
                        name: 'Сегменты',
                        route: ROUTES.SEGMENTS,
                        href: createHref(ROUTES.SEGMENTS),
                        shouldDisplay: userHasAccessTo([
                            'CLIENTS_SEGMENTS_LIST',
                            'CLIENTS_SEGMENTS_MANAGE',
                        ]),
                    },
                    {
                        name: 'Покупки',
                        route: ROUTES.PURCHASES,
                        href: createHref(ROUTES.PURCHASES),
                        shouldDisplay: userHasAccessTo([
                            'CLIENTS_PURCHASES_LIST',
                            'CLIENTS_PURCHASES_MANAGE',
                        ]),
                    },
                    // {
                    //   name: 'Все события',
                    //   route: ROUTES.EVENTS,
                    //   href: createHref(ROUTES.EVENTS),
                    //   // TODO: Сделать доступной только для пользователей с доступом PURCHASE_LIST
                    //   // shouldDisplay: ...
                    // },
                ],
            },
            Object.assign(Object.assign({ id: 'flows', name: 'Цепочки', shouldDisplay: userHasAccessTo(['FLOWS_LIST', 'FLOWS_MANAGE']), icon: _jsx(Flows, { size: iconSize }), iconAccent: _jsx(FlowsSolid, { size: iconSize }) }, (isFlowsEnabled && {
                route: ROUTES.FLOWS,
            })), { childRoutes: [ROUTES.FLOWS_CREATE, ROUTES.FLOWS_EDIT], isLock: !isFlowsEnabled, tooltip: 'Для настройки данного модуля обратитесь к менеджеру MAXMA', items: [
                    Object.assign(Object.assign({ name: 'Цепочки', href: createHref(ROUTES.FLOWS) }, (isFlowsEnabled && {
                        route: ROUTES.FLOWS,
                    })), { tooltip: 'Для настройки данного модуля обратитесь к менеджеру MAXMA', shouldDisplay: userHasAccessTo(['FLOWS_LIST', 'FLOWS_MANAGE']) }),
                ] }),
            {
                id: 'mailings',
                name: 'Рассылки',
                icon: _jsx(Mailings, { size: iconSize }),
                iconAccent: _jsx(MailingsSolid, { size: iconSize }),
                shouldDisplay: userHasAccessTo([
                    'MANUAL_MAILINGS_LIST',
                    'MANUAL_MAILINGS_MANAGE',
                    'AUTO_MAILINGS_LIST',
                    'AUTO_MAILINGS_MANAGE',
                ]),
                items: [
                    {
                        name: 'Ручные',
                        route: ROUTES.MAILINGS_MANUAL,
                        href: createHref(ROUTES.MAILINGS_MANUAL),
                        childRoutes: [
                            ROUTES.MAILINGS_MANUAL_CREATE,
                            ROUTES.MAILINGS_MANUAL_EDIT,
                        ],
                        shouldDisplay: userHasAccessTo([
                            'MANUAL_MAILINGS_LIST',
                            'MANUAL_MAILINGS_MANAGE',
                        ]),
                    },
                    {
                        name: 'Автоматические',
                        route: ROUTES.MAILINGS_AUTOMATIC,
                        href: createHref(ROUTES.MAILINGS_AUTOMATIC),
                        childRoutes: [
                            ROUTES.MAILINGS_AUTOMATIC_CREATE,
                            ROUTES.MAILINGS_AUTOMATIC_EDIT,
                        ],
                        shouldDisplay: userHasAccessTo([
                            'AUTO_MAILINGS_LIST',
                            'AUTO_MAILINGS_MANAGE',
                        ]),
                    },
                ],
            },
            {
                id: 'tools',
                name: 'Инструменты',
                icon: _jsx(Tools, { size: iconSize }),
                iconAccent: _jsx(ToolsSolid, { size: iconSize }),
                shouldDisplay: userHasAccessTo([
                    'WALLET_CARDS_LIST',
                    'WALLET_CARDS_MANAGE',
                    'OFFERS_LIST',
                    'OFFERS_MANAGE',
                    'PROMOCODES_LIST',
                    'PROMOCODES_MANAGE',
                    'GIFTCARDS_LIST',
                    'GIFTCARDS_INSTANCES_MANAGE',
                    'GIFTCARDS_MANAGE',
                ]),
                items: [
                    Object.assign(Object.assign(Object.assign({ name: 'Карты Wallet' }, (hasWalletCardsSupport && {
                        route: ROUTES.WALLET_CARDS,
                        childRoutes: [
                            ROUTES.WALLET_CARDS_CREATE,
                            ROUTES.WALLET_CARDS_EDIT,
                            ROUTES.GEO_PUSH_CREATE,
                            ROUTES.GEO_PUSH_EDIT,
                        ],
                    })), (!hasWalletCardsSupport && {
                        tooltip: 'Для настройки данного модуля обратитесь к менеджеру MAXMA',
                    })), { href: createHref(ROUTES.WALLET_CARDS), shouldDisplay: userHasAccessTo([
                            'WALLET_CARDS_LIST',
                            'WALLET_CARDS_MANAGE',
                        ]) }),
                    Object.assign(Object.assign(Object.assign({ name: 'Акции' }, (hasOffersSupport && {
                        route: ROUTES.OFFERS,
                        childRoutes: [ROUTES.OFFERS_CREATE, ROUTES.OFFERS_EDIT],
                    })), (!hasOffersSupport && {
                        tooltip: 'Для настройки акций\nобратитесь к менеджеру',
                    })), { href: createHref(ROUTES.OFFERS), shouldDisplay: userHasAccessTo(['OFFERS_LIST', 'OFFERS_MANAGE']) }),
                    {
                        name: 'Промокоды',
                        route: ROUTES.PROMOCODES,
                        href: createHref(ROUTES.PROMOCODES),
                        childRoutes: [ROUTES.PROMOCODES_CREATE, ROUTES.PROMOCODES_EDIT],
                        shouldDisplay: userHasAccessTo([
                            'PROMOCODES_LIST',
                            'PROMOCODES_MANAGE',
                        ]),
                    },
                    {
                        name: 'Приведи друга',
                        route: ROUTES.PROMOCODES_FRIEND,
                        href: createHref(ROUTES.PROMOCODES_FRIEND),
                        childRoutes: [
                            ROUTES.PROMOCODES_FRIEND_CREATE,
                            ROUTES.PROMOCODES_FRIEND_EDIT,
                        ],
                        shouldDisplay: userHasAccessTo([
                            'PROMOCODES_LIST',
                            'PROMOCODES_MANAGE',
                        ]),
                    },
                    Object.assign(Object.assign(Object.assign({ name: 'Подарочные карты' }, (hasGiftCardsSupport && {
                        route: ROUTES.GIFT_CARDS,
                        childRoutes: [
                            ROUTES.GIFT_CARDS_CREATE,
                            ROUTES.GIFT_CARDS_EDIT,
                            ROUTES.GIFT_CARDS_INSTANCES,
                        ],
                    })), (!hasGiftCardsSupport && {
                        tooltip: 'Для настройки подарочных карт\nобратитесь к менеджеру',
                    })), { href: createHref(ROUTES.GIFT_CARDS), shouldDisplay: userHasAccessTo([
                            'GIFTCARDS_LIST',
                            'GIFTCARDS_INSTANCES_MANAGE',
                            'GIFTCARDS_MANAGE',
                        ]) }),
                ].filter(Boolean),
            },
        ],
        [
            {
                id: 'billing',
                name: 'Биллинг',
                icon: _jsx(Billing, { size: iconSize }),
                iconAccent: _jsx(BillingSolid, { size: iconSize }),
                shouldDisplay: userHasAccessTo([
                    'BILLING_VIEW',
                    'BILLING_INVOICES_MANAGE',
                ]),
                items: [
                    {
                        name: 'Детализация',
                        route: ROUTES.BILLING,
                        href: createHref(ROUTES.BILLING),
                        shouldDisplay: userHasAccessTo('BILLING_VIEW'),
                    },
                    {
                        name: 'Счета',
                        route: ROUTES.BILLING_INVOICES,
                        href: createHref(ROUTES.BILLING_INVOICES),
                        shouldDisplay: hasInn && userHasAccessTo('BILLING_INVOICES_MANAGE'),
                    },
                ],
            },
            {
                id: 'settings',
                name: 'Настройка',
                shouldDisplay: userHasAccessTo([
                    'SHOPS_LIST',
                    'SHOPS_OWN_MANAGE',
                    'SHOPS_MANAGE',
                    'OPERATORS_LIST',
                    'OPERATORS_MANAGE',
                    'OPERATORS_ADMIN_MANAGE',
                ]),
                icon: _jsx(Settings, { size: iconSize }),
                iconAccent: _jsx(SettingsSolid, { size: iconSize }),
                items: [
                    {
                        name: 'Пользователи',
                        route: ROUTES.SETTINGS_USERS,
                        href: createHref(ROUTES.SETTINGS_USERS),
                        shouldDisplay: userHasAccessTo([
                            'OPERATORS_LIST',
                            'OPERATORS_MANAGE',
                            'OPERATORS_ADMIN_MANAGE',
                        ]),
                    },
                    {
                        name: 'Точки продаж',
                        route: ROUTES.SETTINGS_SHOPS,
                        href: createHref(ROUTES.SETTINGS_SHOPS),
                        shouldDisplay: userHasAccessTo([
                            'SHOPS_LIST',
                            'SHOPS_OWN_MANAGE',
                            'SHOPS_MANAGE',
                        ]),
                    },
                ],
            },
            {
                id: 'knowledge-base',
                name: 'База знаний',
                icon: _jsx(Instructions, { size: iconSize }),
                iconAccent: _jsx(InstructionsSolid, { size: iconSize }),
                items: [
                    {
                        name: 'База знаний',
                        href: 'https://max.ma/help',
                        isExternal: true,
                    },
                ],
                externalLink: 'https://max.ma/help',
            },
        ],
    ];
};
