export const i18nEn = {
    Сводка: 'Dashboard',
    Аналитика: 'Analytics',
    'Список клиентов': 'Customer List',
    Сегменты: 'Segments',
    'Покупки клиентов': 'Sales',
    'Автоматические рассылки': 'Automated Mailings',
    Рассылка: 'Mailing',
    'Автоматическая рассылка': 'Automated Mailing',
    'Ручная рассылка': 'Manual Mailing',
    'Ручные рассылки': 'Manual Mailings',
    'Список выгрузок': 'Exports',
    Промокоды: 'Coupons',
    'Приведи друга': 'Refer-a-Friend',
    Промокод: 'Coupon',
    Код: 'Code',
    Акции: 'Promotions',
    Акция: 'Promotion',
    Возврат: 'Return',
    Пользователей: 'Users',
    'Установлена Wallet-карта': 'A Wallet card is installed',
    'Доступен Telegram': 'Telegram is available',
    'Подтвердили через Double Opt-In': 'Confirmed via Double Opt-In',
    'Недоступно для вашей роли': 'Unavailable for your role',
    'Роль добавлена': 'The role has been added',
    'Роль редактирована': 'The role has been edited',
    'Просмотр роли': 'Viewing a role',
    'Собрать цепочку': 'Assemble the flow',
    'От 02.01.1900': 'From 02.01.1900',
    'До 31.12.2099': 'Until 31.12.2099',
    'за каждого клиента сверх тарифа': 'for each customer over the tariff',
    'Сумма возврата': 'Refund amount',
    'Сумма до скидки': 'Amount before discount',
    'нет характеристик': 'no characteristics',
    'за все покупки': 'for all purchases',
    'Подарочные карты': 'Gift Cards',
    'Подарочная карта': 'Gift Card',
    'Список выпущенных карт': 'Issued Gift Cards',
    Пользователи: 'Users',
    'Точки продаж': 'Places of Sale',
    'Импорт данных': 'Data Import',
    'Новый импорт': 'New Import',
    Расходы: 'Expenses',
    Счета: 'Invoices',
    'Бонусы успешно начислены': 'Points balance increased',
    'Бонусы успешно списаны': 'Points balance lowered',
    'Сегмент сохранен': 'Segment updated',
    'Изменения сохранены': 'Changes updated',
    'Клиент добавлен': 'Customer added',
    'Клиент удалён': 'Customer deleted',
    'Номер телефона': 'Phone Number',
    '№ карты': 'Card No',
    'Сделан возврат': 'Return Made',
    Да: 'Yes',
    'Перезаписать фильтры сегмента {{segmentName}}?': 'Overwrite filters of the segment\n{{segmentName}}?',
    Отмена: 'Cancel',
    Перезаписать: 'Overwrite',
    'Удалить клиента?': 'Delete customer?',
    Удалить: 'Delete',
    'Прибыль по ПЛ': 'Profit (Program)',
    'Выдано карт': 'Members joined',
    'Покупки с картами': 'Members’ sales',
    'Расходы на ПЛ': 'Expenses (Program)',
    'Не потрачено': 'Not spent',
    Использовано: 'Redeemed',
    Сгорело: 'Expired',
    'Выйти без сохранения?': 'Quit without saving?',
    'Установить карту Wallet': 'Install Wallet card',
    отписаться: 'unsubscribe',
    Выйти: 'Quit',
    'Удалить подарочную карту?': 'Delete gift card?',
    'Подарочная карта удалена': 'Gift card deleted',
    'Подарочная карта изменена': 'Gift card updated',
    'Подарочная карта создана': 'Gift card created',
    'Карта успешно активирована': 'Gift card activated',
    'Разблокировать подарочную карту?': 'Unblock gift card?',
    Разблокировать: 'Unblock',
    'Карта успешно разблокирована': 'Gift card unblocked',
    'Заблокировать подарочную карту?': 'Block gift card?',
    Заблокировать: 'Block',
    'Карта успешно заблокирована': 'Gift card blocked',
    'Импорт удалён': 'Import deleted',
    'Загрузка из буфера обмена': 'Load from a paste',
    'Загрузка из': 'Load from',
    'Импорт создан': 'Import has been created',
    'Удалить импорт?': 'Delete import?',
    'Удалить импорт': 'Delete import',
    'Счет выставлен': 'Invoice created',
    Копия: 'Copy of',
    OP_OR: 'or',
    OP_NOT: 'not',
    OP_AND: 'and',
    MAILINGS_SELECTED: 'Selected {{count}} $t(mailing, { "count": {{count}} })',
    'Рассылка создана': 'Mailing created',
    'Рассылка сохранена': 'Mailing updated',
    'Не выбраны каналы коммуникации': 'Communication channels not specified',
    'Вы действительно хотите очистить дизайн?': 'Do you really want to reset the design?',
    'Сообщение отправлено': 'Message sent',
    'У вас остался несохраненный дизайн': 'You have unsaved design',
    'Хотите его восстановить?': 'Would you like to restore it?',
    Нет: 'No',
    'Содержимое письма будет удалено.': 'Message content will be deleted.',
    Очистить: 'Reset',
    'совершение покупки': 'making a purchase',
    'совершения покупки': 'making a purchase',
    Ручные: 'Manual',
    Цепочки: 'Flows',
    Авторассылки: 'Automated Mailings',
    'Попадание в сегмент': 'Getting into a segment',
    'Покидание сегмента': 'Leaving a segment',
    'Начисление бонусов за покупку': 'Points earned for a purchase',
    'Возврат товара': 'Returning goods',
    'День рождения': 'Birthday',
    'Сгорание бонусов': 'Point expiring',
    'Совершение покупки': 'Making a purchase',
    'День рождения ребёнка': 'Child’s Birthday',
    'Брошенная корзина': 'Abandoned cart',
    'Просмотр товара или категории': 'Product or category view',
    'Цена на товар в корзине снижена': 'Cart item price has been discounted',
    'Цена на товар в избранном снижена': 'Favorite item price has been discounted',
    'Без подсчета': 'No counting',
    'Подтверждение подписки на рассылку': 'Double opt-in',
    'Дозаполнение хотя бы 1 поля анкеты': 'Filling up at least 1 field',
    '100% доставка рассылки': '100% mailing delivery',
    'Прохождение online-опроса': 'Completing an online poll',
    'Установка карты Wallet': 'Installing Wallet card',
    'Переход по ссылке': 'Following a link',
    'Канал включен': 'Enabled',
    'Канал выключен': 'Disabled',
    Выключен: 'Disabled',
    Покупка: 'Purchase',
    'Сумма покупки': 'Purchase Total',
    'Место покупки': 'Place of Sale',
    Наименование: 'Title',
    Товар: 'Product',
    Артикул: 'SKU Code',
    'Товар {{ num }}': 'Product {{ num }}',
    Условия: 'Conditions',
    Количество: 'Quantity',
    Сумма: 'Amount',
    'Акция удалена': 'Promotion deleted',
    'Акция изменена': 'Promotion update',
    'Акция создана': 'Promotion created',
    TOOLTIP_ARBITRATION_TYPE: "Mechanics how this promotion combines with others.<br /><br /><b>Is exclusive</b> - if promotion applies, no other promotion will be applied to purchase.<br /><br /><b>Takes over the other</b> - if applies to purchase item, promotion of type Gives way to other won't be applied to it.<br /><br /><b>Gives way to other</b> - applies to items which doesn't have promotion Takes over the other.<br /><br /><b>Wins when more profitable</b> - applies to item if gives more discount (or more bonuses) than other promotion applied to it.<br /><br /><b>Appends</b> - applies to item even if there are other promotions applied.",
    'Удалить акцию?': 'Delete promotion?',
    'Промокод удалён': 'Coupon deleted',
    'Код изменён': 'Code updated',
    'Код создан': 'Code created',
    'Промокод изменён': 'Coupon updated',
    'Промокод создан': 'Coupon created',
    'Удалить код?': 'Delete code?',
    'Удалить промокод?': 'Delete coupon?',
    'Код удалён': 'Code deleted',
    'Покупка добавлена': 'Purchase created',
    'Покупка изменена': 'Purchase updated',
    'Покупка удалена': 'Purchase deleted',
    'Удалить покупку?': 'Delete purchase?',
    'Название отчета': 'Report name',
    Переименовать: 'Rename',
    'Уйти без сохранения?': 'Leave without saving ?',
    Уйти: 'Leave',
    'Если введенные данные важны, сохраните их перед уходом с формы': 'If the entered data is important, save it before leaving the form',
    'Сбросить изменения': 'Reset the changes',
    'Остаться на форме': 'Stay in form',
    'Новый отчет': 'New Report',
    'Отчет сохранен': 'Report updated',
    'Отчет удален': 'Report deleted',
    'Сохранить изменения в отчете {{name}}?': 'Save changes in report\n{{name}}?',
    Остаться: 'Cancel',
    'Не сохранять': "Don't save",
    Сохранить: 'Save',
    'Удалить «{{name}}»?': 'Delete «{{name}}»?',
    'Сегмент удален': 'Segment deleted',
    'Клиенты удалены': 'Customers deleted',
    'Удаление сегмента «{{segmentName}}»': 'Deleting "{{segmentName}}"',
    'Также будет удалена статистика и отключены рассылки, назначенные для этого сегмента.': 'Statistics for this segment will become unavailable. Mailings of this segment will stop.',
    'Безвозвратное удаление клиентов': 'Customers deletion',
    'Клиенты сегмента «{{segmentName}}» будут навсегда удалены из системы': 'Customers of the segment "{{segmentName}}" will be deleted (unrecoverable)',
    'Точка продаж сохранена': 'Place of Sale updated',
    'Точка продаж создана': 'Place of Sale created',
    'Точка продаж удалена': 'Place of Sale deleted',
    'Удалить точку продаж?': 'Delete Place of Sale?',
    'Пользователь удален': 'User deleted',
    'Пользователь сохранен': 'User updated',
    'Удалить пользователя?': 'Delete user?',
    'Система лояльности': 'Loyalty platform',
    'Ошибка при обращении к серверу': 'Error on requesting the server',
    'В запросе содержатся некорректные данные': 'Request contains invalid parameters',
    'Время действия сессии истекло, пожалуйста, войдите снова': 'Session is over, please log in again',
    'Недостаточно прав для доступа к ресурсу': 'Access to the requested resource is restricted',
    'Ошибка при обращении к серверу, повторите попытку позже': 'Error on requesting the server, try again later',
    Клиенты: 'Customers',
    Имя: 'Name',
    Фамилия: 'Last/Family Name',
    Отчество: 'Middle Name',
    Пол: 'Gender',
    Возраст: 'Age',
    Дети: 'Children',
    'Имя ребёнка': 'Child’s Name',
    'Возраст ребёнка': 'Child’s Age',
    'Пол ребёнка': 'Child’s Gender',
    'Дополнительные поля': 'Extra Fields',
    'Каналы коммуникации': 'Communication channels',
    Подписки: 'Subscribed To',
    Телефон: 'Phone Number',
    'Телефон указан': 'Number Specified',
    'Поиск по телефонам': 'Search by phones',
    Email: 'Email',
    'Email указан': 'Email Specified',
    'Поиск по email': 'Search by email',
    'Подтвердил подписку': 'Double Opt-In',
    'Ошибка в контактах': 'Wrong Contact',
    'Программа лояльности': 'Loyalty Program',
    'Статус участия': 'Participation Status',
    'Вступление в программу': 'Joining the Program',
    'Дата вступления': 'Sign Up Date',
    'Дней в программе': 'Days in Program',
    'Место вступления': 'Sign Up Location',
    'Кто добавил': 'Sign Up Clerk',
    'Поиск по номерам карт': 'Search by card numbers',
    'Карты Wallet': 'Wallet Cards',
    Уровень: 'Level',
    Бонусы: 'Points',
    Стоимость: 'Price',
    'Цена до скидки': 'Price before discount',
    'Бонусов на счету': 'Points Balance',
    'Бонусов использовано': 'Points Redeemed',
    'Бонусов сгорело': 'Points Expired',
    'Дата сгорания бонусов': 'Expire Date',
    Покупки: 'Sales',
    'Количество покупок': '# of Purchases',
    'Количество позиций': '# of Items',
    'Сумма покупок': 'Amount of Purchases',
    'сумма покупок': 'amount of purchases',
    СуммаПокупок: 'AmountOfPurchases',
    'Средний чек': 'Average Sale',
    'Максимальный чек': 'Max Sale',
    'Дней между покупками': 'Days Between Purchases',
    'Дата совершения покупок': 'Date of Purchase',
    'Дата покупки': 'Date of Purchase',
    'Покупка дней назад': 'Purchase Days Ago',
    'Дней без покупок': 'Days w/o Purchase',
    'Дата п/покупки': 'Date of Last Purchase',
    'Где покупал ранее': 'Where did I buy it earlier',
    Продавец: 'Clerk',
    Кассир: 'Clerk',
    'Что покупал ранее': 'What I bought earlier',
    Возвраты: 'Returns',
    'Количество возвратов': '# of Returns',
    'Сумма возвратов': 'Amount of Returns',
    'Дата возврата': 'Date of Return',
    'Возврат дней назад': 'Return Days Ago',
    'Дней без возвратов': 'Days w/o Return',
    'Дата п/возврата': 'Date of Last Return',
    'Где возвращал': 'Returned Where',
    'Место возврата': 'Place of return',
    'Кто принял': 'Return Clerk',
    'Что возвращал': 'Returned What',
    Категория: 'Category',
    'Категория в-та': 'Return Category',
    'Артикул в-та': 'Return SKU',
    'Наименование в-та': 'Return Title',
    Цена: 'Price',
    'Наценка, %': 'Markup, %',
    'Переоценка, %': 'Revaluation, %',
    'Скидка кассы, %': 'PoS discount, %',
    Свойства: 'Properties',
    Атрибуты: 'Attributes',
    'Исполнилось лет': 'Age',
    Сегмент: 'Segment',
    'Количество бонусов': 'Bonus Balance',
    'Драйверы покупки': 'Purchase drivers',
    Рассылки: 'Mailings',
    Автоматические: 'Automated',
    'Где продано': 'Place of Sale',
    'Место продажи': 'Place of Sale',
    'Что продано': 'Bought What',
    'Где возвращено': 'Returned Where',
    'Что возвращено': 'Returned What',
    'Участник программы': 'Program Participant',
    'Не задан': 'Not specified',
    Мужской: 'Male',
    Женский: 'Female',
    'SMS и Viber': 'SMS, Push and Viber',
    Указан: 'Specified',
    'Не указан': 'Not Specified',
    Подтвердил: 'Opted',
    'Не подтвердил': 'Not Opted',
    'Ошибка в телефоне': 'Mistake in phone number',
    'Ошибка в email': 'Mistake in email',
    Заблокирован: 'Blocked',
    Подписчик: 'Subscriber',
    'Apple Wallet': 'Apple Wallet',
    'Google Pay': 'Google Pay',
    'Да, участник': 'Yes, participant',
    Клиент: 'Customer',
    ИмяКлиента: 'CustomerName',
    ИмяОтчествоКлиента: 'CustomerFullName',
    ИмяОтчествоФамилия: 'FullName',
    ФамилияКлиента: 'CustomerLastName',
    ОтчествоКлиента: 'CustomerMiddleName',
    СгораетБонусов: 'PointsExpiring',
    ВсегоБонусов: 'PointsBalance',
    БонусовНаСчету: 'ClientBonuses',
    ОжидаемыхБонусов: 'ClientPendingBonuses',
    ПокупокНаСчетчике: 'CounterAmount',
    ПокупокДоВознаграждения: 'CounterAmountToReward',
    ДатаСгоранияСчетчика: 'CounterExpirationDate',
    ПоследнийПуш: 'LastPushMessage',
    НомерКарты: 'CardNumber',
    УровеньКлиента: 'ClientLevelName',
    БлижайшаяДатаСгоранияБонусов: 'PointsNearestExpireDate',
    НачисленоБонусовЗаПокупку: 'PurchasePointsEarned',
    ДатаСгоранияБонусовЗаПокупку: 'PurchasePointsExpireDate',
    СуммаДоУровня: 'AmountToNextLevel',
    ОтмененоБонусовЗаВозврат: 'ReturnPointsCancelled',
    НачисленоБонусовПоощрения: 'PromoPointsEarned',
    ДатаСгоранияБонусовПоощрения: 'PromoPointsExpireDate',
    'PH.PROMOCODE': 'Coupon',
    КодПодарочнойКарты: 'GiftCardCoupon',
    СсылкаЭлКошелька: 'WalletLink',
    'Товары по триггеру': 'Triggered products',
    НазваниеТовара: 'ProductTitle',
    КартинкаТовара: 'ProductImage',
    СтараяЦенаТовара: 'ProductOldPrice',
    ЦенаТовара: 'ProductPrice',
    СсылкаНаТовар: 'ProductLink',
    'Повторять для каждого товара': 'Repeat for each product',
    'Рекомендуемые товары': 'Recommended products',
    'Рассылка удалена': 'Mailing deleted',
    'Служебные ссылки': 'Service Links',
    'MAILING.CREATE.ALPHABET_MIXED': 'In the SMS text you’ve used characters of different languages in the same single word ({{words}}). Cell providers consider such messages as spam and may reject delivery of them.',
    Отписаться: 'Unsubscribe',
    'MAILING.CREATE.MISSING_UNSUB_LINK': 'Insert the "unsubscribe" link so that the email is not sent to spam',
    'MAILING.CREATE.ZERO_TOTAL_BONUSES': 'You’ve used "!PointsBalance" in the text of {{channels}}, but not filtered customers by the criteria "balance greater then 0 points". Perhaps the customers with zero balance should be excluded from the recipients.',
    'Удаление рассылки «{{mailingName}}»': 'Deletion of mailing "{{mailingName}}"',
    Вернуться: 'Back',
    Продолжить: 'Continue',
    'Забыли «{{placeholder}}»?': 'Forgotten "{{placeholder}}"?',
    'Вы не добавили параметр «{{placeholder}}» в текст {{channels}}': 'You’ve not added "{{placeholder}}" parameter to {{channels}}',
    'Смешанные символы': 'Mixed alphabets',
    'Ссылка «Отписаться»': 'Unsubscribe link',
    'Напоминаем! У вас 0 бонусов': 'Remember! You have 0 points',
    Подвал: 'Footer',
    подвал: 'footer',
    'Карта Wallet': 'Wallet Card',
    карта: 'card',
    телефон: 'phone',
    'Мобильные приложения': 'Mobile apps',
    'Товары по триггеру (брошенная корзина)': 'Triggered products (abandoned cart)',
    товары: 'products',
    'Товары по триггеру (сниженная цена)': 'Triggered products (price drop)',
    новинки: 'new products',
    'последние просмотренные': 'last viewed',
    бестселлеры: 'bestsellers',
    'Все клиенты': 'All customers',
    'Клиенты без карт': 'Not Members',
    'Все держатели карт': 'All Loyalty Members',
    Сегодня: 'Today',
    Вчера: 'Yesterday',
    Неделя: 'Week',
    Месяц: 'Month',
    Квартал: 'Quarter',
    Год: 'Year',
    'Всё время': 'All Time',
    TOOLTIP_SEGMENTS: 'Customer groups, grouped by amount, frequency of purchases, date of last purchase, and date of join the Program. The goal of segmentation is — customer retention by personalizing mailings',
    TOOLTIP_MAILING_CR: 'Conversion rate. Ratio of customers who performed the targeted action to the number of customers who have received mailing. Shown the average value across all channels. Failed/bounced SMS/emails are not taken into account',
    'Сумма затрат на программу лояльности для сегмента за выбранный период. Учитываются затраты на платные рассылки, скидки и списание бонусов клиентами': 'Total expenses for the loyalty program for the segment for the selected period. Costs for paid mailings, discounts, and points redemption by customers are taken into account',
    'Средняя сумма затрат на получение одной покупки с применением программы лояльности для данного сегмента. Учитываются затраты на рассылки, скидки и списание бонусов': 'The average cost of receiving one purchase using the loyalty program for a given segment. Costs for mailings, discounts and points are taken into account',
    Прибыль: 'Profit',
    Выручка: 'Revenue',
    'Клиентов в сегменте': '# of Customers',
    CR: 'CR',
    'Ст-ть покупателя': 'ACQ Cost',
    'Изменить фильтры': 'Update Filters',
    'Удалить сегмент': 'Delete Segment',
    'Удалить клиентов': 'Delete Customers',
    'Номер телефона, ФИО, email или отсканируйте карту': 'Phone number, full name, email or scan your card',
    'Отменить редактирование': 'Cancel editing',
    'Сбросить фильтр': 'Reset filter',
    'Дата вступления клиента в клуб': 'Date when customer has joined the loyalty program',
    'Указывается доступная сумма бонусов на счету клиента, а также сумма еще не подтвержденных бонусов (значение в скобках)': 'Current points balance as well as the number of pending points (not yet available for redemption)',
    'Статус согласия на получение SMS и/или Email рассылок': 'Status of SMS and email subscriptions',
    'Обозначение принадлежности клиента к сегменту; сегментация нужна для персонализации рассылок, а также построения аналитики': 'List of segments the customer belongs to. Segmentation is needed for more personalized marketing, as well as analytics',
    'Новый сегмент': 'Make Segment',
    Найти: 'Find',
    'Редактирование сегмента:': 'Segment editing:',
    'Сегмент:': 'Segment:',
    'Клиентов:': 'Customers:',
    'Добавить клиента': 'Add Customer',
    'Выгрузить в Excel': 'Export to Excel',
    'Имя клиента': 'Customer Name',
    'Сумма покупок с применением программы лояльности за выбранный период. Учитываются чеки с начислением и списанием бонусов. Покупки по которым совершен возврат не учитываются': 'The amount of sales made by the loyalty members for the selected period (excluding returns)',
    'Валовая прибыль покупок с применением программы лояльности за выбранный период. Учитываются чеки с начислением и списанием бонусов. Покупки по которым совершен возврат не учитываются': 'Gross profit of sales made by the loyalty members for the selected period (excluding returns)',
    'Сумма затрат на программу лояльности за выбранный период. Учитываются списания бонусов, скидки, затраты на рассылки и абонентская плата': 'Total amount of the loyalty program expenses for the selected period. Bonus redemption, discounts, mailing costs and subscription fee are taken into account',
    'Количество клиентов, которое вступило в программу лояльности за выбранный период': 'The number of customers who signed up for the loyalty program for the selected period',
    'Доля покупок с использованием программы лояльности за выбранный период. Учитываются чеки как с начислением, так и со списанием бонусов': 'Share of purchases made by the loyalty members. May be unavailable for some PoS',
    'Средняя сумма затрат на получение одной покупки с применением программы лояльности. Учитываются затраты на рассылки, скидки и списание баллов': 'Customer acquisition cost. Mailing cost, discounts and point deductions are taken into the account',
    'Количество бонусов, которое было начислено в заданный период и остается на бонусных счетах клиентов': 'Share of points that remain on a customer’s balance from total earned within the selected period',
    'Количество бонусов из числа начисленных за заданный период, которое было использовано клиентами': 'Share of points redeemed by customers from total earned within the selected period',
    'Количество бонусов из числа начисленных за заданный период, которое сгорело (не были использованы) или было отменено (например, в связи с возвратами)': 'Share of points that have expired or have been cancelled (for example, due to a refund) from total earned within the selected period',
    'Показатель процента заполнения полей, важных для коммуникации с клиентами': 'Percent of customers provided the data important for communication',
    'Доля от общей прибыли': 'Share of total profit',
    'Доля от общей выручки': 'Share of total revenue',
    'Доля расходов к прибыли': 'Expenses to profit ratio',
    'Доля расходов к выручке': 'Expenses to revenue ratio',
    'Согласны на рассылку SMS': 'Opted for SMS',
    'Согласны на рассылку Email': 'Opted for Email',
    'Заполнено имя': 'Name filled',
    'Заполнен Email': 'Email filled',
    'Заполнен день рождения': 'Birthday filled',
    'Заполнен пол': 'Gender filled',
    Демография: 'Demographics',
    День: 'Day',
    Группировка: 'Group by',
    'Сумма затрат на программу лояльности за выбранный период. Учитываются списания бонусов, затраты на рассылки и абонентская плата': 'The amount of expenses for the loyalty program for the selected period. Points deduction, mailing costs and subscription fees are taken into account',
    'Средняя сумма затрат на получение одной покупки с применением программы лояльности. Учитываются затраты на рассылки и списание баллов': 'Average cost per purchase using the loyalty program. Costs for mailings and points deduction are taken into account',
    'Количество бонусов, которое было начислено в заданный период и может быть использовано сегодня или позднее': 'The amount of points accumulated in a given period that remain on a customer’s balance',
    'Количество бонусов, которое было использовано клиентами за выбранный период': 'The amount of points used by a customer in a given period (redeemed)',
    'Количество бонусов, которое сгорело за выбранный период (не были использованы) или было отменено (например, в связи с возвратами)': 'The amount of points that have expired or have been cancelled (for example, due to a refund)',
    'Новая выгрузка': 'New Export',
    Название: 'Title',
    'Детальная информация о клиентах за выбранный период': 'Detailed customer data for a specified period',
    'Применить фильтры': 'Apply Filters',
    'Список клиентов и их детей': 'Customers and Children',
    'Базовая информация о клиенте, совмещенная с именем и датой рождения его детей': 'Basic customer info with the names of their children and children’s birthdates',
    'Список покупок': 'Purchase List',
    'Детальная информация о продажах за выбранный период': 'Detailed sales data for a specified period',
    'Загрузка клиентов по операторам': 'Customer Data Quality',
    'Информация о количестве и качестве загруженных операторами анкет': 'Operator performance on filling data of the customers',
    'Движение бонусов': 'Points Status',
    'Детальная информация об изменениях в бонусах за выбранный период': 'Detailed data about points transfer for a specified period',
    Взаиморасчеты: 'Mutual settlement',
    'Отчет, позволяющий произвести взаиморасчеты между торговыми точками\n                            по начисленным и списанным бонусам, а также погашенным подарочным картам': 'A report that allows you to make mutual settlements between shops for earned and redeemed points, as well as redeemed gift cards',
    'Выгрузки:': 'Exports',
    'Пока нет выгрузок': 'You have no exports yet',
    'Дата создания': 'Date Added',
    Статус: 'Status',
    'Уникальный идентификатор подарочной карты для интеграции с программой лояльности': 'Unique gift card identificator for integration with the loyalty program',
    'Неиспользованный остаток сгорает после первой совершённой покупки': 'Unused amount expires after first purchase',
    'не ограничен (от)': 'unbounded (from)',
    'не ограничен (до)': 'unbounded (to)',
    'Карта активируется при передаче клиенту и только после этого становится доступной для использования. Активация должна поддерживаться вашим кассовым ПО, уточните у менеджера, доступна ли эта опция для вас.': 'Card is getting activated upon handing over to the customer and only after that it can be used. It is necessary that your PoS software to compatible with card activation, please contact to your manager to clarify this.',
    'Код в формате EAN-13, идентифицирующий карты данного номинала': 'EAN-13 code identifying cards of given amount',
    '2-10 цифр': '2-10 digits',
    'Редактирование подарочной карты': 'Gift Card Editing',
    'Создание подарочной карты': 'Gift Card Creation',
    Основное: 'General',
    'Тип карты': 'Card Type',
    Печатная: 'Printable',
    Электронная: 'Digital',
    Идентификатор: 'Identificator',
    'Сумма номинала': 'Amount',
    'сгораемый остаток': 'single use gift card',
    'Период действия карты': 'Validity Dates',
    'требуется активация карты': 'require card activation',
    'Срок действия с момента активации': 'Valid Since Activation',
    дней: 'days',
    'Печатная карта': 'Printable Card',
    'Товарный код': 'Product Barcode',
    'Артикул карты': 'SKU',
    'Тип штрих-кода': 'Unique Code Format',
    'Префикс штрих-кода': 'Unique Code Prefix',
    'Создать карту': 'Create Card',
    'Штрих-код': 'Code',
    'Выпущено карт:': 'Cards issued:',
    'Выгрузить коды в файл': 'Export codes to file',
    'Номер карты': 'Card No',
    'Дата активации': 'Activation Date',
    'Дата истечения': 'Expiration Date',
    Остаток: 'Amount',
    gift_status_not_activated: 'Not activated',
    gift_status_active: 'Activated',
    gift_status_blocked: 'Blocked',
    gift_status_expired: 'Expired',
    gift_status_burned: 'Redeemed',
    Активировать: 'Activate',
    'Сохранить черновик': 'Save draft',
    'Заменять существующие данные': 'Update existing data',
    Лист: 'Sheet',
    'Пропустить заголовок': 'Skip Header',
    'Пропустить заголовок таблицы из файла': 'Skip Header',
    строк: 'lines',
    'Не загружать': 'Do not load',
    'ФИО одним полем': 'Full name in a single field',
    'Дата рождения': 'Date of birth',
    'Внешний идентификатор': 'External ID',
    'Дата регистрации': 'Sign up date',
    'Дата последней покупки': 'Last purchase date',
    'Кол-во покупок': 'Number of purchases',
    'Кол-во бонусов': 'Bonus points amount',
    'Согласие на любые рассылки': 'Subscribed to everything',
    'Согласие на SMS': 'Subscribed to SMS',
    'Согласие на Email': 'Subscribed to Email',
    'Отображено строк:': 'Lines shown:',
    из: 'of',
    Загрузить: 'Load',
    Загружается: 'Loading',
    Выполнена: 'Done',
    Ошибка: 'Error',
    Черновик: 'Draft',
    'Скачать файл с результатом': 'Download Results File',
    'Загрузить данные': 'Load Data',
    'Загрузить клиентов': 'Load Customers',
    'Дата загрузки': 'Load Date',
    Тип: 'Type',
    'Всего записей': 'Lines Total',
    Загружено: 'Loaded',
    'С ошибкой': 'Failed',
    Редактировать: 'Edit',
    'Перезагрузить результат': 'Reload Results',
    'Актуальные счета': 'Current Invoices',
    'Дата выставления': 'Issue Date',
    Номер: 'Number',
    'Дата оплаты': 'Payment Date',
    'Не оплачен': 'Not Paid',
    Оплачен: 'Paid',
    'Частично оплачен': 'Partially Paid',
    Отменен: 'Canceled',
    'Запросить счет': 'Get Invoice',
    'Имя пользователя': 'Username',
    Пароль: 'Password',
    Войти: 'Login',
    'Рассчитано приблизительно на основе средней стоимости отправки, количества получателей и длины сообщения. Фактические затраты могут отличаться.': 'Calculated roughly based on average send cost, number of recipients, and message length. Final costs may vary',
    Общее: 'General',
    Push: 'Push',
    Viber: 'Viber',
    SMS: 'SMS',
    Завершение: 'Completion',
    'Прогнозируемые затраты до': 'Projected costs up to',
    'в неделю.': 'per week',
    'Тестовый Viber': 'Test Viber',
    'Тестовый Push': 'Test Push',
    'Тестовое SMS': 'Test SMS',
    'Тестовое письмо': 'Test Email',
    Назад: 'Back',
    Далее: 'Next',
    Отправлять: 'Send',
    Запланировать: 'Schedule',
    'MAILING.MESSAGE_WILL_BE_RECEIVED': "Message will be received by <b>{{ clientsCount | numberRound }}</b> {{ clientsCount | plural:'customer':'customers' }}.",
    EMAIL_INCLUDE_ABANDONED_CART: 'The "Triggered products" block appeared in the editor. Insert it in the body of the email to show a list of abandoned cart items (no more than 10 products will be displayed)',
    EMAIL_INCLUDE_DROPPED_PRICE_ITEMS: 'The "Triggered products" block appeared in the editor. Insert it in the body of the email to show a list of products which price has dropped (no more than 10 products will be displayed)',
    EMAIL_INCLUDE_ITEMS_LIST: 'Check to make the "Recommended products" block appear in the editor. Insert it in the body of the email to display a list of product recommendations (the number of products you specify will be displayed)',
    Отправитель: 'Sender',
    'Тема письма': 'Subject',
    Шаблон: 'Template',
    Содержимое: 'Body',
    Требуется: 'Required',
    'Выбрать дизайн': 'Choose design',
    'или создать новый': 'or create new one',
    Новый: 'New',
    'Очистить дизайн': 'Clean the design',
    'Вложить в письмо брошенные товары': 'Include abandoned products in Email',
    'Вложить в письмо товары со сниженной ценой': 'Include price drop products in Email',
    'Вложить в письмо товарные рекомендации': 'Include product recommendations in Email',
    Подборка: 'Product Set',
    PRODUCTS_IN_EMAIL: "{{ count | plural:'product':'products' }} in Email",
    'Используется основной период действия акции или указанное значение': 'Used general validity dates or specified value',
    'Используется только в интерфейсе MAXMA': 'Used only in the MAXMA interface',
    'Для показа конверсии (CR) достижения цели рассылки': 'To show the conversion (CR) of reaching the campaign goal',
    'Событие (триггер), при наступлении которого произойдет отправка': 'What triggers the campaign',
    Событие: 'Event',
    'События сайта': 'Website Events',
    Прочее: 'Other',
    TOOLTIP_MAILING_REPEAT_SENDING: 'Yes - the campaign will take place every time the trigger is fired (for example, annually for a birthday or when customer re-joins the segment); No - the customer who received the campaign will never receive it again',
    OFFSET_SIGN_BEFORE: 'before',
    OFFSET_SIGN_AFTER: 'after',
    REWARD_PROMOCODE: 'Coupon "{{ promocodeCode }}"',
    REWARD_GIFT_CARD: 'Gift card "{{ name }}" ({{ amount }})',
    Начислить: 'Accrue',
    ', сгорают через': ', expiring in',
    'сгорают через': 'expiring in',
    сгорают: 'expiring',
    'после начисления': 'after accrual',
    REWARD_OFFER: 'Promotion "{{ title }}", valid',
    REWARD_OFFER_SINCE_MAILING: 'since mailing',
    Каналы: 'Channels',
    Получатели: 'Recipients',
    'Задайте получателей рассылки.': 'Choose mailing recipients',
    'Считать CR': 'Count the CR',
    'подтверждение подписки (Email)': 'double opt-in (Email)',
    'заполнение анкеты': 'filling out a questionnaire',
    'доставки рассылки': 'mailings delivery',
    'прохождения опроса': 'passing the survey',
    'установку карты Wallet': 'Wallet Card install',
    'переход по ссылке (Email)': 'following a link (Email)',
    нет: 'no',
    'не считать': 'no',
    'Дата и время': 'Date and Time',
    Сейчас: 'Now',
    'Отложить до': 'Schedule',
    'Ваш часовой пояс:': 'Your time zone:',
    'MAILING.SEND_ON': 'Send On',
    сразу: 'At event',
    через: 'After event',
    за: 'Before event',
    'попадания в сегмент': 'getting into the segment',
    'покидания сегмента': 'leaving the segment',
    'активации бонусов': 'points activation',
    возврата: 'returning goods',
    'ухода с сайта (брошенная корзина)': 'leaving website (abandoned cart)',
    'ухода с сайта (брошенный просмотр товара или категории)': 'leaving website (viewed products or categories)',
    'снижения цены на товар в корзине': 'price dropped for a cart item',
    'снижения цены на товар в избранном': 'price dropped for a favorite item',
    'дня рождения': 'birthday',
    'дня рождения ребенка': 'child’s birthday',
    'сгорания бонусов': 'points expire',
    'Задайте необходимые критерии.': 'Specify criteria.',
    'Повторять отправку': 'Repeat Sending',
    Поощрения: 'Rewards',
    Добавить: 'Add New',
    TOOLTIP_MAILING_PUSH_TEXT: 'Any unicode characters are allowed in the text, incl. international letters. Recommended length no more than 80 characters',
    'Push-нотификации': 'Push Notifications',
    'Текст сообщения': 'Message Text',
    'Символов:': 'Characters:',
    'Динамические параметры:': 'Ad-hoc Parameters:',
    'Показывать баннер': 'Show Banner For',
    'Картинка загружена': 'Picture is downloaded',
    'Поместите картинку': 'Drop Image',
    '(png, 1125x433, до 10 Мб)': '(png, 1125x433, up to 10Mb)',
    'Слишком большой файл': 'File is too large',
    'Размер должен быть 1125x433': 'Size must be 1125x433',
    'Размер должен быть 1125×433': 'Size must be 1125×433',
    'Размер должен быть 150×150': 'Size must be 150×150',
    'Размер должен быть 200×200': 'Size must be 200×200',
    'Размер должен быть 150-450×150': 'Size must be 150-450×150',
    'Размер должен быть не менее 1125x433': 'Size must be at least 1125x433',
    'Размер должен быть не менее 1125×433': 'Size must be at least 1125×433',
    'Размер должен быть не менее 150×150': 'Size must be at least 150×150',
    'Размер должен быть не менее 200×200': 'Size must be at least 200×200',
    'Размер должен быть не менее 150-450×150': 'Size must be at least 150-450×150',
    'Необходим формат .png': 'Required format .png',
    TOOLTIP_MAILING_SMS_TEXT: 'Any unicode characters are allowed in the text messages, incl. international letters. Recommended length of SMS no more than 6 parts',
    SMS_TEXT_LENGTH: "Text consists of {{ smsMessagesCount }} {{ smsMessagesCount | plural:'part':'parts' }} ({{ smsTextLength }} {{ smsTextLength | plural:'character':'characters' }})",
    TOOLTIP_MAILING_VIBER_TEXT: 'Any unicode characters are acceptable in the text, incl. international letters. Recommended length no more than 400 characters',
    'Обязательна при использовании кнопки или картинки': 'Mandatory when using a button or picture',
    'Текст кнопки': 'Button Label',
    '(квадратная и до 1 Мб)': '(square and up 1 mb)',
    'Только квадратная картинка': 'Square picture only',
    Ссылка: 'Link',
    TOOLTIP_MAILING_CHANNELS: 'Communication channels used in the mailing list. Up to 3 channels are used: Email, Viber messenger and Text messaging. Text message is sent if the message did not reach via Viber or the channel is completely deactivated',
    TOOLTIP_ROI_PROFIT: 'Return on investment. Calculated as (Profit − Expenses) ∕ Expenses × 100%',
    TOOLTIP_ROI_REVENUE: 'Return on investment. Calculated as (Revenue − Expenses) ∕ Expenses × 100%',
    TOOLTIP_MAILING_PROFIT: 'Gross profit from orders and purchases made by Program members within two weeks after the mailing. If the customer received more than one mailing, the profit goes to the most recent one. Cancelled orders are not counted',
    TOOLTIP_MAILING_REVENUE: 'The amount of orders and purchases made by Program members within two weeks after the mailing. If the customer received more than one mailing, the revenue goes to the most recent one. Cancelled orders are not counted',
    'Сумма затрат на рассылку за выбранный период. Учитываются списания бонусов, скидки и затраты на рассылки по всем каналам': 'Total cost for the mailing within the selected period. Bonus redemption, discounts and costs for mailings across all channels are taken into account',
    Запланирована: 'Planned',
    Запланирован: 'Planned',
    Отправляется: 'Sending',
    Дата: 'Date',
    ROI: 'ROI',
    MAILING_RECIPIENTS_SEGMENTS_COUNT: "{{ segmentsCount }} {{ segmentsCount | plural:'segment':'segments' }}",
    исключения: 'exclusions',
    фильтры: 'filters',
    'Сделать копию': 'Make a copy',
    Посмотреть: 'View',
    'Итого за период': 'Total for period',
    'Добавить рассылку': 'Create Mailing',
    Отображать: 'Show',
    результатов: 'results',
    Применить: 'Apply',
    'Выберите дизайн': 'Choose Design',
    'Начать с чистого листа': 'Start from scratch',
    'На основе предыдущей рассылки': 'Based on previous mailing',
    'На основе шаблона': 'Based on the template',
    Обзор: 'Overview',
    'Редактировать профиль': 'Edit profile',
    Подтверждается: 'Pending',
    Карта: 'Card',
    Покупок: 'Purchases',
    Заказов: 'Orders',
    Возвратов: 'Returns',
    'История клиента': 'Customer History',
    сейчас: 'now',
    'В резерве': 'Reserved',
    'за заказом или покупкой': 'for an order or purchase',
    Потрачено: 'Has been spend',
    'на покупку': 'on purchase',
    Накоплено: 'Saved up',
    'за покупку': 'for purchase',
    Отменено: 'Cancelled',
    'за возврат': 'on return',
    Начислено: 'Earned',
    оператором: 'by operator',
    Списано: 'Redeemed',
    Возвращено: 'Returned',
    'за покупку друга': 'for a friend’s purchase',
    'Клиент вступил в Клуб': 'Customer joined the Program',
    'Начислить, списать бонусы': 'Append, take off bonus',
    'Добавить покупку': 'Add Sale',
    HISTORY_BONUSES_AMOUNT: '{{amount}} $t(bonuses, { "count": {{count}} })',
    BONUSES_AMOUNT_MIN_ERROR_MESSAGE: 'at least {{amount}} $t(bonuses, { "count": {{count}} })',
    BONUSES_AMOUNT_MAX_ERROR_MESSAGE: 'no more than {{amount}} $t(bonuses, { "count": {{count}} })',
    BONUSES_AMOUNT: '{{amount}} $t(bonuses, { "count": {{count}} })',
    BONUSES_LABEL: '$t(bonuses, { "count": {{count}} })',
    bonuses_one: 'point',
    bonuses_other: 'points',
    'Номер телефона, ФИО, email или карта': 'Phone number, full name, email or card',
    'Клиенту будут направлены стандартные сообщения о начислении/списании бонусов': 'Customer will get standard messages about earned/redeemed points',
    'Начислить бонусы': 'Append points',
    'Списать бонусы': 'Subtract points',
    'Для клиента': 'Customer',
    'Тип операции': 'Operation Type',
    Списать: 'Redeem',
    'Срок действия': 'Validity Dates',
    'как у обычных бонусов': 'same as regular points',
    '1 день': '1 day',
    '3 дня': '3 days',
    '1 неделя': '1 week',
    '2 недели': '2 weeks',
    '1 месяц': '1 month',
    '3 месяца': '3 months',
    '6 месяцев': ' 6 months',
    '1 год': '1 year',
    Комментарий: 'Comment',
    'Уведомить клиента': 'Notify customer',
    'Вставьте данные': 'Paste your data',
    'Поместите файл': 'Place a File',
    '(XLSX, CSV, до 15 Мб)': '(XLSX, CSV, up to 15 MB)',
    'XLSX, CSV, до 15 Мб': 'XLSX, CSV, up to 15 MB',
    'Необходим формат .xlsx, .csv или .tsv': 'Required format .xlsx, .csv or .tsv',
    или: 'or',
    'загрузите из буфера обмена': 'load by copy and paste',
    'Вставить из буфера обмена': 'Load by copy and paste',
    'Запросить новый счет на пополнение': 'Request a new invoice for top up',
    'На сумму': 'Amount',
    Выставить: 'Request',
    GIFT_CARD_GENERATE_MODAL_TITLE: 'Gift Card "{{ giftCard.name }}", {{ giftCard.amount }}',
    PROMOCODE_GENERATE_MODAL_TITLE: 'Promocode "{{ promocode.code }}"',
    'Сгенерировать и выгрузить дополнительные карты': 'Generate more cards',
    GIFT_CARD_GENERATE_MODAL_OPTION_GENERATE: 'Export existing cards ({{ amount }} pcs)',
    GIFT_CARD_GENERATE_HELP: 'from {{ from }} to {{ to }} cards',
    PROMOCODE_GENERATE_HELP: 'from {{ from }} to {{ to }} codes',
    PROMOCODE_GENERATE_REPORT_NOTICE: 'File with codes will be available in section Exports during 14 days.',
    Выгрузить: 'Export',
    'Сгенерировать коды': 'Generate Codes',
    Сгенерировать: 'Generate',
    'Создать акцию': 'Create Promotion',
    'Создать промокод': 'Create Coupon',
    Статистика: 'Statistics',
    'Количество сообщений, в которых получатель нажал «отписаться» или «спам», а также доля таких сообщений от количества доставленных.': 'The number of messages in which the recipient clicked "unsubscribe" or "spam", as well as the proportion of such messages from the number delivered.',
    TOOLTIP_MAILING_POPUP_CR: 'Conversion rate. Ratio of customers who performed the targeted action ({{ goalDescr }}) to the number of customers who have received marketing. Shown the average value across all channels. Failed/bounced SMS/emails are not taken into account',
    'Сумма затрат по всем каналам рассылки': 'Total expenses for all mailing channels',
    TOOLTIP_FAILED_COUNT: '{{amount}} $t(errors, { "count": {{count}} })',
    errors_one: 'error',
    errors_other: 'errors',
    'Триггер:': 'Trigger:',
    'Цель:': 'Goal:',
    Вкл: 'State',
    'Вкл/Выкл': 'State',
    Отправлено: 'Sent',
    Ожидают: 'Waiting',
    'Сообщения могут ожидать отправки, если они:': 'Messages can be waiting to be sent if they:',
    'находятся в очереди и будут обработаны в ближайшее время;': 'they are in the queue and will be processed soon;',
    'отложены на утро с учетом часового пояса получателя.': "they are postponed until the morning, taking into account the recipient's time zone.",
    'Open Rate — количество просмотренных сообщений, а также доля просмотренных от числа доставленных.': 'Open Rate — the number of viewed messages, as well as the percentage of viewed messages from the number of delivered ones.',
    'Click-to-Open Rate — количество сообщений, в которых получатель перешел по ссылке, а также доля таких сообщений от числа просмотренных.': 'Click-to-Open Rate — the number of messages in which the recipient clicked on the link, as well as the proportion of such messages from the number viewed.',
    Доставлено: 'Delivered',
    Прочитано: 'Open',
    Кликов: 'Clicks',
    'Доступ открыт до': 'Access is open until',
    'Полный доступ': 'Full access',
    Настройки: 'Settings',
    Выгрузка: 'Export',
    'Название роли': 'Role name',
    'Начисление бонусов': 'Accrual of bonuses',
    Маскировать: 'Masking',
    'Ручн. рассылки': 'Manual mailing lists',
    'Авт. рассылки': 'Auto mailing lists',
    'Точки продажи': 'Points of sale',
    'Персон. данные': 'Personal data',
    'Промокоды и приведи друга': 'Promo codes\nand refferal',
    'Активация и блокировка': 'Activation and blocking',
    Отписок: 'Unsubscribed',
    'Сумма скидок': 'Discount amount',
    'Оплачено бонусами': 'Paid with bonuses',
    Итого: 'Total',
    'ДД.ММ.ГГГГ': 'DD.MM.YYYY',
    'Чтобы привязать к клиенту карту, поместите курсор в данное поле и отсканируйте карту, номер карты отобразится в поле': 'Focus this field and scan the card, the card number should then appear in the field',
    Отсканируйте: 'Scan',
    'Удалить ребенка': 'Delete child row',
    'Добавить ребенка': 'Add child row',
    'Нераспознанное имя:': 'Unparsed name:',
    'Имя и отчество': 'Name',
    'Мобильный телефон': 'Mobile Phone',
    'Некорректный телефон': 'Incorrect phone number',
    'Некорректный Email': 'Incorrect Email',
    'Место выдачи карты': 'Signing In Place',
    'Уровень клиента': 'Customer Level',
    Ребенок: 'Child',
    'Согласие на рассылку': 'Subscribed to',
    'Сохранить изменения': 'Save Changes',
    'Удалить клиента': 'Delete Customer',
    'Наименование или артикул': 'Title or SKU',
    'Выберите клиента': 'Choose Client',
    'Указать состав покупки': 'Specify purchase content',
    'Кол-во': 'Qty',
    PURCHASE_TO_REDEEM_AMOUNT: '$t(bonuses, { "count": {{count}} })',
    PURCHASE_FROM_AVAILABLE: 'from {{amount}} available',
    Скидка: 'Discount',
    'К оплате': 'To Be Paid',
    'Будет начислено': 'To Earn',
    PURCHASE_TO_EARN_AMOUNT: '{{amount}} $t(bonuses, { "count": {{count}} })',
    '0 бонусов': '0 points',
    'Сумма со скидкой': 'Price With Discount',
    'Бонусов списано': 'Points Redeemed',
    'Бонусов начислено': 'Points Earned',
    Оплачено: 'Paid',
    'Удалить покупку': 'Delete Purchase',
    Регион: 'Region',
    'Каталог товаров': 'Product catalog',
    Создать: 'Create',
    'Изменить покупку': 'Change the purchase',
    'Редактирование сегмента': 'Segment editing',
    Цвет: 'Color',
    'Сохранить сегмент': 'Save Segment',
    'Создать сегмент': 'Create a segment',
    'Новая точка продаж': 'New place of sale',
    'Редактирование точки продаж': 'Place of sale editing',
    Поищи: 'Look Up',
    email: 'email',
    'Можете указать несколько адресов email, по одному на каждую строку': 'Several email addresses can be specified, one per line',
    'Можете указать несколько номеров в любом формате, по одному на каждую строку': 'Several numbers can be specified in any format, one per line',
    'Тестовое сообщение': 'Test message',
    'Номера телефонов': 'Phone Numbers',
    Пользователь: 'User',
    'Сгенерировать пароль': 'Random Password',
    Логин: 'Login',
    'Только латиница': 'Latin only',
    'Придумайте пароль': 'Choose a Password',
    'Роль (уровень доступа)': 'Role (Access Level)',
    'Отправить данные для входа на почту': 'Send login information to email',
    'Видят только администраторы': 'Can be seen by administrators only',
    'Создать пользователя': 'Create User',
    'или укажите сегмент, телефон': 'or specify segment, phone',
    'Редактирование акции': 'Editing Promotion',
    'Создание акции': 'Promotion Creation',
    Включена: 'Active',
    Остановлена: 'Stopped',
    Включен: 'Active',
    Остановлен: 'Stopped',
    Участники: 'Participants',
    'Некоторые из телефонов содержат ошибки.': 'Some of the mobile numbers contain errors.',
    'Убрать некорректные': 'Delete incorrect',
    'Выберите хотя бы одного участника': 'Choose at least one participant',
    MAILING_RECIPIENTS: 'Mailing recipients <2>"{{ name }}"</2>',
    'Период действия акции': 'Validity Dates',
    'Кол-во участий': 'Participation Limit',
    'не ограничено': 'unlimited',
    'не более': 'not more than',
    неделю: 'week',
    акцию: 'promotion',
    'Эта акция': 'This offer',
    'единственная в чеке': 'is exclusive',
    'вытесняет другие': 'takes over the other',
    'уступает другим': 'gives way to other',
    'срабатывает, если выгоднее': 'wins when more profitable',
    'суммируется с другими': 'appends to other',
    'не выбрано': 'not selected',
    'Условия срабатывания': 'Usage Conditions',
    'При покупке': 'On Buying',
    'В чеке есть': 'Have in purchase',
    'Время покупки': 'Purchasing Time',
    Пн: 'Mo',
    Вт: 'Tu',
    Ср: 'We',
    Чт: 'Th',
    Пт: 'Fr',
    Сб: 'Sa',
    Вс: 'Su',
    Вознаграждения: 'Rewards',
    Вознаграждение: 'Reward',
    скидка: 'discount',
    'растущая скидка': 'growing discount',
    бонусы: 'bonus points',
    'фиксированная цена': 'fixed price',
    'начисляются за': 'credited for',
    прибавляются: 'Added',
    'начисляются вместо': 'Credited instead',
    'к бонусам по ПЛ': 'to the LP bonuses',
    'бонусов ПЛ': 'of LP bonuses',
    'в дату': 'on the date',
    никогда: 'never',
    активируются: 'activated on',
    'после активации': 'after activation',
    'На товар по счету': 'On N-th item',
    'на чек': 'on purchase',
    'на любой товар': 'on any product',
    'на указанный товар': 'on specified product',
    'на комплект (комбо)': 'on set',
    'Выберите товары': 'Pick Products',
    'Выберите хотя бы один критерий применения': 'Choose at least one application criteria',
    'на каждый': 'on each:',
    '-й товар в чеке': '-th item in the sale',
    OFFER_NOT_MORE_N_ITEMS: '$t(offer_not_more_items, { "count": {{count}} }) for purchase',
    offer_not_more_items_one: 'item',
    offer_not_more_items_other: 'items',
    'выбирать самый дешевый товар': 'apply on the least expensive item',
    'разделить скидку между всеми товарами в чеке': 'divide discount between products in the sale',
    'Сервис на этапе интеграции. Абонентская плата не списывается, рассылки не срабатывают.': 'Account is not activated, the subscription fee is not being charged, mailing disabled.',
    'Работа сервиса может быть приостановлена': 'The service may be suspended',
    'Пополните баланс': 'Top up the account balance',
    'Пополните баланс до {{date}}': 'Top up the account balance by {{date}}',
    BALANCE_FORECAST_ALERT: 'Current balance {{currentBalance}} enough for $t(days, { "count": {{days}} })',
    days_one: '~{{count}} day',
    days_other: '~{{count}} days',
    MIN_DAYS_ERROR_MESSAGE: 'at least {{amount}} $t(min_max_days, { "count": {{days}} })',
    MAX_DAYS_ERROR_MESSAGE: 'no more than {{amount}} $t(min_max_days, { "count": {{days}} })',
    min_max_days_one: 'day',
    min_max_days_other: 'days',
    'Работа сервиса приостановлена. Абонентская плата не списывается, рассылки не срабатывают.': 'Account is suspended. The subscription fee is not being charged, mailing disabled.',
    LOGOUT: 'Logout',
    BALANCE_FORECAST_TOOLTIP: 'Enough for $t(days, { "count": {{days}} })',
    Клиента: 'Customer',
    Покупку: 'Sale',
    'Общий – может быть применен разными клиентами. Персональный – может быть применен только тем клиентом, кому был направлен в рассылке.': 'Public - can be used by any customer. Personal - can be used only by customer whom the code was sent to.',
    'не ограничен': 'unbounded',
    'Минимальная сумма чека для применения промокода': 'Minimum sale amount for using the coupon',
    'Сумма бонусов, которую получит рекомендатель сразу после покупки друга': 'Amount of points the referrer will get for the friend’s purchase',
    'Важно проинформировать рекомендателя о вознаграждении, чтобы он продолжал рассказывать о вашем бренде': 'It’s important to let referrer know about the reward to motivate them for further promotion',
    'Редактирование промокода': 'Editing Coupon',
    'Создание промокода': 'Coupon Creation',
    'Редактирование кода': 'Editing Code',
    'Создание кода': 'Create Code',
    'Написание кода': 'Code',
    'Префикс кода': 'Code Prefix',
    'Создать код': 'Create Code',
    'Тип кода': 'Code Type',
    'Общий код': 'Public Code',
    'Персональные коды': 'Personal Codes',
    'Период действия': 'Validity Dates',
    'Сумма покупки от': 'Min Purchase Amount',
    'Только на': 'Only On',
    'Любую покупку': 'Any purchase',
    'Первую покупку': 'First purchase',
    'Одну покупку': 'One purchase',
    'В торговых точках': 'In shops',
    'Размер скидки': 'Discount',
    'Вознаграждение рекомендателя': 'Referrer Reward',
    '% от покупки друга в бонусах': '% of a friend’s purchase in points',
    'Начислять за': 'Make for',
    'Все покупки друга': 'All friend’s purchases',
    'Одну покупку друга': 'One friend’s purchase',
    'Уведомить о начислении': 'Notify referrer on getting the reward',
    Действующие: 'Ongoing',
    Архивные: 'Completed',
    'Место, где была совершена покупка': 'Place of sale',
    'Учетная запись, под которой провели покупку': 'Clerk who registered the sale',
    'Чеков:': 'Sales:',
    'Дата операции': 'Date',
    'Точка продажи': 'Place of Sale',
    Сотрудник: 'Employee',
    EMPLOYEE: '{{amount}} $t(employee, { "count": {{count}} })',
    employee_one: 'employee',
    employee_other: 'employees',
    Бонусов: 'Points',
    списано: 'Redeemed',
    начислено: 'Earned',
    'Для настройки акций обратитесь к менеджеру': 'Ask your manager to set up promotions',
    'Для настройки подарочных карт обратитесь к менеджеру': 'Ask your manager to set up gift cards',
    Отчеты: 'Reports',
    Выгрузки: 'Exports',
    Инструменты: 'Tools',
    Биллинг: 'Billing',
    Поддержка: 'Support',
    Настройка: 'Settings',
    'Указана окончательная стоимость, вне зависимости от местонахождения клиента. Все сообщения будут отправляться от имени Вашего бренда': 'The final cost is indicated, regardless of the customer’s location. All the messages will be sent on behalf of your brand',
    'Количество целевых действий канала, за которое списывается плата, за отчетный период. Для Email и SMS — это отправка сообщения, для Viber — факт доставки сообщения': 'The number of targeted channel actions for which the fee is charged for the reporting period. For Email and SMS - this is the sending of a message, for Viber - the fact of message delivery.',
    'Плата за использование платформы за выбранный период': 'Payment for using the platform for the selected period',
    'Количество дней в выбранном расчетном периоде, за которое будет начислена плата, согласно тарифу': 'The number of days in the selected billing period for which the fee will be charged, according to the tariff.',
    'Детализация расходов за:': 'Expense details for:',
    'Расходы на рассылки': 'Mailings Expenses',
    'Стоимость сообщения': 'Cost per Message',
    'Количество сообщений': '# of Messages',
    Подытог: 'Subtotal',
    'Где покупал хотя бы раз': 'Where did you buy it at least once',
    'Последнее место покупки': 'Last place of purchase',
    'Самое частое место покупки': 'The most frequent place of purchase',
    'Абонентская плата': 'Subscription Fee',
    'Стоимость дня': 'Cost per Day',
    'Количество дней': '# of Days',
    'Ежедневное списание': 'Daily fee',
    'Итого:': 'Total:',
    'Сумма покупок за период (исключая возвраты), а также прибыль за счет истекших подарочных карт и сгоревших остатков на подарочных картах.': 'Revenue from the sales for the period (returns not included), plus profit from unclaimed expired gift cards.',
    'Валовая прибыль с покупок за период (исключая возвраты), а также прибыль за счет истекших подарочных карт и сгоревших остатков на подарочных картах.': 'Profit from the sales for the period (returns not included), plus profit from unclaimed expired gift cards.',
    'Сумма списанных бонусов и затрат на рассылки, в которых распространялась эта карта': 'Total points redeemed and mailing expenses in which this card was distributed',
    Выпущено: 'Issued',
    'Действия пользователей платформы': 'Actions of the platform users',
    'Действия пользователей в платформе, такие как создание, изменение или удаление клиентов, акций, рассылок и так далее': 'User actions in the platform, such as creating, changing or deleting clients, promotions, newsletters, and so on',
    шт: 'pcs',
    Активировано: 'Activated',
    'Все события': 'All events',
    Погашено: 'Redeemed',
    Истекло: 'Expired',
    'Сумма применённых скидок и списанных бонусов': 'The amount of discounts applied and points redeemed',
    TOOLTIP_OFFER_PROFIT: 'Gross profit from items sold at retail and ordered under the promotion during the specified period. Returned items and cancelled orders are not counted',
    TOOLTIP_OFFER_REVENUE: 'The amount of items sold at retail and ordered under the promotion during the specified period. Returned items and cancelled orders are not counted',
    TOOLTIP_OFFER_COUNT: 'Number of purchases under the promotion, including completed orders',
    TOOLTIP_OFFER_ORDER_COUNT: 'Number of orders in operation (not canceled, not executed) under the promotion',
    TOOLTIP_OFFER_INACTIVE: 'Deactivated',
    TOOLTIP_PROMOCODE_PROFIT: 'Gross profit from retail sales and orders using the coupon for the selected period. Returned items and cancelled orders are not counted',
    TOOLTIP_PROMOCODE_REVENUE: 'The amount of purchases and orders using the coupon for the selected period. Returned items and cancelled orders are not counted',
    TOOLTIP_PROMOCODE_COUNT: 'The number of purchases using the coupon, including completed orders',
    TOOLTIP_PROMOCODE_OFFER_COUNT: 'The number of orders in operation (not canceled, not executed) using the coupon',
    'Сумма применённых скидок, списанных бонусов и затрат на рассылки, в которых распространялся этот код': 'Amount of applied discounts, redeemed bonuses, and mailing expenses in which this code was distributed',
    Применений: 'Usages',
    'Могут использовать': 'May Use',
    Товары: 'Products',
    'Список товаров': 'Products list',
    'Список подешевевших товаров': 'Discounted products list',
    От: 'From',
    'Рекомендации товаров': 'Product recommendations',
    'брошенная корзина': 'abandoned cart',
    'просмотр товара или категории': 'product or category view',
    'цена на товар в корзине снижена': 'cart item price has been discounted',
    'цена на товар в избранном снижена': 'favorite item price has been discounted',
    'Пользователей:': 'Users:',
    'Добавить пользователя': 'Add User',
    'Дата добавления': 'Date Added',
    Роль: 'Role',
    'Последняя авторизация': 'Last Login',
    'Точек продаж:': 'Place of Sales:',
    'Часовой пояс': 'Time Zone',
    'Расчетный период': 'Settlement period',
    'Социальные сети': 'Social networks',
    соцсети: 'social',
    'Создание рассылки': 'Mailing Creation',
    'Источник покупки': 'Sale source',
    'Адрес Email': 'Email',
    Адрес: 'Address',
    'введите адрес': 'enter the address',
    Координаты: 'Coordinates',
    'введите координаты': 'enter the coordinates',
    метров: 'meters',
    'Уведомления вблизи магазинов': 'Notifications near stores',
    'Телефон с установленной картой покажет сообщение покупателю на основе геолокации. В Apple Wallet поддерживается не более 10 точек. В GPay геопуши в настоящее время не поддерживаются.': 'A phone with a card installed will show a message to the customer based on geolocation. Apple Wallet supports a maximum of 10 locations. In GPay, geo pushes are not currently supported.',
    всегда: 'always',
    'заданный период': 'specified period',
    'Удалить геопуш': 'Delete geo push item',
    'Как найти координаты магазина': "How to find the store's coordinates",
    'на дистанции до': 'at a distance of up to',
    MAX_METERS_ERROR: 'maximum of {{amount}} $t(meters, { "count": {{meters}} })',
    'по часовому поясу Europe/Moscow (UTC+03)': 'by Europe/Moscow time zone (UTC+03)',
    'Системная роль': 'System role',
    'Можно активировать не более 10 геоточек': 'You can activate up to 10 geo points',
    'Вступление в Клуб': 'Joining the Program',
    'Дней в Клубе': 'Days in Program',
    'Редактирование карты': 'Card editing',
    'Создание карты': 'Card creation',
    'Просмотр рассылки': 'Viewing Mailing',
    'Баланс:': 'Balance:',
    января: 'January',
    февраля: 'February',
    марта: 'March',
    апреля: 'April',
    мая: 'May',
    июня: 'June',
    июля: 'July',
    августа: 'August',
    сентября: 'September',
    октября: 'October',
    ноября: 'November',
    декабря: 'December',
    от: 'from',
    до: 'to',
    Включить: 'Matches',
    Исключить: 'Doesn’t Match',
    'Загружается...': 'Loading...',
    'Можно указать несколько вариантов, по одному на строку': 'Multiple options can be specified, one per line',
    Входит: 'Part of',
    'Не входит': 'But not of',
    'Загрузить еще...': 'Load more...',
    'Общие настройки': 'Common Settings',
    'Настройки успешно сохранены': 'Settings updated',
    'Задайте любой Ваш вопрос службе поддержки:': 'Ask support service any of your questions:',
    'Сумма покупок за период. Учитываются только покупки с применением карт, исключая возвраты': 'Total amount of purchases for the period. Only purchases of the Program members are counted (excluding returns)',
    'Валовая прибыль с покупок за период. Учитываются только покупки с применением карт, исключая возвраты': 'Gross profit from purchases for the period. Only purchases of the Program members are counted (excluding returns)',
    'Первую покупку друга': 'First friend’s purchase',
    Номинал: 'Denomination',
    'Реферальный код': 'Referral code',
    'Реферальные коды': 'Referral Codes',
    'К предыдущему периоду': 'To the previous period',
    'Расходы по программе': 'Program expenses',
    'Всего выдано карт': 'Total members',
    'Процент применения карт': 'Engagement rate',
    'Среднее значение за год': 'Average value per year',
    и: 'and',
    'Сегменты клиентов': 'Segments',
    'Стоимость покупателя': 'ACQ Cost',
    'За период': 'For the period',
    'За все время': 'For all time',
    'Сгорело или отменено': 'Expired or cancelled',
    'Качество клиентской базы': 'Data Completeness',
    Ограничения: 'Constraints',
    'Применим на': 'Allowed Products',
    'Можно не указывать одну из дат.': 'One of date may be omitted.',
    'Можно указывать не все значения.': 'Some values may be omitted.',
    'Можно не указывать одно из значений.': 'One of values may be omitted.',
    'Еще из': 'More from',
    'Кол-во:': 'Quantity:',
    ИмяРебенкаКто: 'ChildNameWho',
    ИмяРебенкаКому: 'ChildNameWhom',
    Сообщение: 'Message',
    после: 'after',
    OFFSET_SIGN_AT: 'soon after',
    Фильтры: 'Filters',
    бон: 'pt',
    LANG_SWITCH: 'На русский',
    INTL_NUMBER: '{{n, number}}',
    INTL_DATE: '{{d, datetime}}',
    EMPTY_SEARCH: 'empty',
    Сбросить: 'Reset',
    Метрики: 'Metrics',
    'Изменить метрику': 'Change metric',
    Маркетинг: 'Marketing',
    'Фин. показатели': 'Fin. indicators',
    Чек: 'Sale',
    'Карты лояльности': 'Loyalty cards',
    Скидки: 'Discounts',
    'Скидки по ПЛ': 'Discounts (Program)',
    'Срок жизни клиента': "Client's lifespan",
    'Процент повторных покупок': 'Repeat rate',
    'Покупок на клиента': 'Average purchases per client',
    'Выручка по ПЛ': 'Revenue (Program)',
    Наценка: 'Mark-up',
    'Наценка по ПЛ': 'Mark-up (Program)',
    'Средний чек по ПЛ': 'Average Sale (Program)',
    'Кол-во чеков': 'Count of purchases',
    'Кол-во чеков по ПЛ': 'Count of purchases (Program)',
    'Позиций на чек': 'Items per check',
    'Позиций на чек по ПЛ': 'Items per check (Program)',
    'Рост базы': 'Signup count',
    Изменить: 'Edit',
    'Только числа': 'Only numbers',
    'Не более {{max}}': 'No more than {{max}}',
    'Не менее {{min}}': 'At least {{min}}',
    'Ошибка ввода': 'Input error',
    не: 'not',
    'База знаний': 'Knowledge Base',
    'по годам': 'years',
    'по месяцам': 'months',
    'по промокодам': 'coupons',
    'по торговым точкам': 'shops',
    'по кассирам': 'clerks',
    'по рассылкам': 'mailings',
    'по полу': 'gender',
    'по сегментам': 'segments',
    'по RFM сегментам': 'RFM segments',
    CLIENTS_PANEL_HEADER: '{{amount}} $t(clients, { "count": {{count}} })',
    CLIENTS_WILL_BE_RECEIVED: '{{amount}} $t(clients, { "count": {{count}} })',
    SEGMENT_CLIENTS_DELETE: 'Delete {{amount}} $t(clients, { "count": {{count}} }) of segment\n{{segmentName}}?',
    CLIENTS: '{{amount}} $t(clients, { "count": {{count}} })',
    clients_one: 'client',
    clients_other: 'clients',
    SEGMENTS_PANEL_HEADER: '{{amount}} $t(segments, { "count": {{count}} })',
    MAILING_RECIPIENTS_SEGMENTS: '{{amount}} $t(segments, { "count": {{count}} })',
    segments_one: 'segment',
    segments_other: 'segments',
    PURCHASES_PANEL_HEADER: '{{amount}} $t(purchases, { "count": {{count}} })',
    CLIENT_PURCHASES_COUNT: '{{amount}} $t(purchases, { "count": {{count}} })',
    purchases_one: 'sale',
    purchases_other: 'sales',
    CLIENT_RETURNS_COUNT: '{{amount}} $t(returns, { "count": {{count}} })',
    returns_one: 'return',
    returns_other: 'returns',
    USERS_PANEL_HEADER: '{{amount}} $t(users, { "count": {{count}} })',
    users_one: 'user',
    users_other: 'users',
    ROLE_FORM_MODAL_COUNTER: 'It will apply to  {{amount}} $t(users, { "count": {{count}} })',
    'Роль ни на кого не назначена': 'The role is not assigned to anyone',
    Пользователя: 'User',
    'Удалить роль': 'Delete a role',
    SHOPS_PANEL_HEADER: '{{amount}} $t(shops, { "count": {{count}} })',
    shops_one: 'place of sales',
    shops_other: 'places of sales',
    млн: 'M',
    тыс: 'K',
    Состав: 'Structure',
    'без названия': 'untitled',
    'Без названия': 'untitled',
    'Списание с карты бонусов, начисленных при покупке': 'Debiting bonuses accrued during purchase from the card',
    'Возврат на карту бонусов, списанных при покупке': 'Refund to the card of bonuses debited during purchase',
    Характеристики: 'Characteristics',
    'Данные сравниваются с предыдущим периодом': 'The data is compared with the previous period',
    'Показать расчеты за': 'Show calculations for',
    'Расчеты за': 'Calculations for',
    'Показывать отправленные за': 'Show sent for',
    'Показать покупки за': 'Show purchases for',
    'Сбросить поиск': 'Reset search',
    'Название рассылки': 'Mailing name',
    'Название акции': 'Offer name',
    'Название промокода': 'Promocode name',
    'Название кода': 'Code name',
    'Ничего не найдено': 'Nothing was found',
    'Попробуйте изменить запрос': 'Try changing the query',
    'Название сегмента': 'Segment name',
    'Название подарочной карты': 'Gift card name',
    'Сбросить фильтры': 'Reset filters',
    Превью: 'Preview',
    'Без ФИО': 'No Fullname',
    MAILING_MODAL_HEADER: '{{name}} mailing statistics',
    'нет покупок': 'no purchases',
    'нет возвратов': 'no returns',
    'Без наименования': 'No Title',
    Детализация: 'Details',
    Округление: 'Rounding',
    Роли: 'Roles',
    'Создать роль': 'Create a role',
    'Создание роли': 'Creating a role',
    'Редактирование роли': 'Editing a role',
    'Нет доступа': 'No access',
    'только тех, кого пользователь добавил в систему сам': 'only those whom the user\nadded to the system himself',
    'Скрывает:': 'Hides:',
    'Email, кроме домена email': 'Email, except for the email domain',
    'Телефон, кроме последних цифр': 'Phone number, except for the last digits',
    Фамилию: 'Last name',
    'Карту, весь номер кроме последних цифр': 'The card, the whole number except the last digits',
    Просмотр: 'Viewing',
    Редактирование: 'Editing',
    'Всех клиентов': 'All clients',
    'Только своих': 'Only their own',
    'Всех пользователей': 'All users',
    'Всех, кроме админов': 'All except the admins',
    FIELD_MAX_LENGTH_ERROR: 'maximum of {{amount}} $t(symbols, { "count": {{count}} })',
    FIELD_MIN_LENGTH_ERROR: 'at least {{amount}} $t(symbols, { "count": {{count}} })',
    'Не верный формат. Введите широту и долготу через пробел, запятую или точку с запятой': 'Invalid format. Enter latitude and longitude separated by a space, comma, or semicolon',
    'широта, долгота': 'latitude, longitude',
    GEO_PUSH_MESSAGE_PLACEHOLDER: 'We are very close! In the Panorama shopping center, 4th floor, near the cinema. Open from 10 to 21',
    symbols_one: 'character',
    symbols_other: 'characters',
    FIELD_REQUIRED_ERROR: 'required for input',
    FIELDS_REQUIRED_ERROR: 'fields are required to be entered',
    'введите имя кассира': "enter the cashie's name",
    'Уникальный для каждого магазина.': 'Unique for each place of sale.',
    Подробнее: 'Read more',
    'введите код': 'enter the code',
    'нет акций': 'no shares',
    'введите название точки': 'enter the name of the place of sale',
    'выберите регион': 'select a timezone',
    PRODUCT_CATALOG_FORM_LABEL: 'Product Catalog',
    'выберите каталог': 'select a catalog',
    'Создать точку': 'Create a place of sale',
    'Удалить точку': 'Delete the place of sale',
    'Для входа в систему': 'Login Information',
    'только латиница': 'latin only',
    'введите логин': 'enter the login',
    'введите пароль': 'enter the password',
    сгенерировать: 'generate',
    'Скидка кассы': 'Cash register discount',
    'Ручная скидка': 'Manual discount',
    'по правилам ПЛ': 'according to the rules of the LP',
    'Если введенные данные важны, сохраните их перед переходом на другую страницу': 'If the entered data is important, save it before going to another page',
    'Пойти сохранять': 'Go save',
    'неверный Email': 'invalid Email',
    'отправить данные для входа на почту': 'send login information to email',
    'Для отображения в системе': 'Display Information',
    'введите имя пользователя': 'enter the user name',
    'выберите роль': 'select a role',
    'видят только администраторы': 'only administrators can see it',
    'введите комментарий': 'enter a comment',
    'Удалить клиента {{name}}?': 'Delete customer\n{{name}}?',
    'Удалить покупку клиента {{clientName}}?': 'Delete the purchase of the client\n{{clientName}}?',
    'Удалить сегмент {{name}}?': 'Delete segment\n{{name}}?',
    'Это действие необратимо.': 'This is irreversible.',
    'Удалить подарочную карту {{name}}?': 'Delete gift card\n{{name}}?',
    'Удалить карту': 'Delete gift card',
    'Удалить пользователя {{name}}?': 'Delete user\n{{name}}?',
    'Удалить пользователя': 'Delete user',
    'Удалить роль {{name}}?': 'Delete role\n{{name}}?',
    'Удалить магазин {{name}}?': 'Delete place of sale {{name}}?',
    'Удалить магазин?': 'Delete place of sale?',
    'Перенести продажи удаляемой точки в...': 'Transfer sales of the place being deleted to...',
    'выберите точку продаж': 'select a place of sale',
    'Удалить рассылку {{name}}?': 'Delete mailing\n{{name}}?',
    'Удалить рассылку': 'Delete mailing',
    'Удалить промокод {{name}}?': 'Delete coupon\n{{name}}?',
    'Удалить код приведи друга {{name}}?': 'Delete code\n{{name}}?',
    'Удалить промокод': 'Delete coupon',
    'Удалить код': 'Delete code',
    'Удалить акцию {{name}}?': 'Delete promotion\n{{name}}?',
    'Удалить акцию': 'Delete promotion',
    'Разблокировать подарочную карту {{name}} №{{n}}?': 'Unblock gift card\n{{name}} #{{n}}?',
    'Разблокировать подарочную карту №{{n}}?': 'Unblock gift card #{{n}}?',
    'Заблокировать подарочную карту {{name}} №{{n}}?': 'Block gift card\n{{name}} #{{n}}?',
    'Заблокировать подарочную карту №{{n}}?': 'Block gift card #{{n}}?',
    'Разблокировать карту': 'Unblock gift card',
    'Заблокировать карту': 'Block gift card',
    'Удалить отчет {{name}}?': 'Delete report\n{{name}}?',
    'Удалить отчет': 'Delete report',
    'Сохранить отчет': 'Save report',
    'Личные данные': 'Personal Data',
    'Имя и Отчество': 'First and Middle Names',
    NAME_PLACEHOLDER: 'first name',
    MIDDLE_NAME_PLACEHOLDER: 'middle name',
    LAST_NAME_PLACEHOLDER: 'last name',
    женский: 'female',
    мужской: 'male',
    'не задан': 'not specified',
    'дд.мм.гггг': 'dd.mm.yyyy',
    'введите номер': 'enter the phone number',
    'неверный номер телефона': 'invalid phone number',
    'выберите уровень': 'select a level',
    'выберите место выдачи': 'select a signing in place',
    'Поместите курсор в данное поле и отсканируйте карту, номер карты отобразится в поле': 'Focus this field and scan the card, the card number should then appear in the field',
    'отсканируйте карту': 'scan the card',
    'неверный номер карты': 'invalid card number',
    CLIENT_PHONE_SUBSCRIBED_MESSAGE: 'subscribed to<1/>SMS, Push and Viber',
    CLIENT_EMAIL_SUBSCRIBED_MESSAGE: 'subscribed to<1/>email',
    'Дополнительные данные': 'Additional Data',
    'введите имя ребенка': "enter the child's name",
    'день рождения': 'birthday',
    введите: 'enter',
    выберите: 'select',
    'рассылка на этот номер прекращена из-за ошибок доставки': 'mailing to this number has been discontinued\ndue to delivery errors',
    'рассылка на эту почту прекращена из-за ошибок доставки': 'mailing to this email has been discontinued\ndue to delivery errors',
    начислить: 'accrue',
    списать: 'redeem',
    'введите число': 'enter a number',
    'только числа': 'only numbers',
    'Срок действия бонусов': 'Bonus Validity Dates',
    'выберите срок действия': 'select validity dates',
    'например, почему бонусный баланс был изменен': 'for example, why was the bonus balance changed',
    'только для пользователей платформы, клиент его не видит': 'only for platform users, it is not visible to the client',
    BONUSES_NOTIFY_PHONE_MESSAGE: 'Send the client<1/>SMS-notification\nof bonus accrual<3/>',
    'Формат сообщения: Имя, вам начислено N бонусов (всего на счету N). Хорошего дня!': 'Message format: Name, you have been awarded N bonuses (N total on the account). Have a nice day!',
    'введите номер телефона, ФИО, Email или карту': 'enter a phone number, full name, email or card',
    'Затраты на платформу': 'Platform costs',
    'Затраты на рассылки': 'Mailing costs',
    METRIC_TOOLTIP_revenue: 'The sum of all customer purchases on all receipts.',
    METRIC_TOOLTIP_revenueProgram: 'The sum of all purchases of customers participating in the loyalty program.',
    METRIC_TOOLTIP_expensesFull: 'Total expenses for the loyalty program (platform operation, discounts, bonuses, mailing expenses).',
    METRIC_TOOLTIP_expensesPlatform: 'The cost of maintenance of the platform and additional modules.',
    METRIC_TOOLTIP_expensesMailing: 'The cost of sent mailings via channels (SMS, Email, Viber).',
    METRIC_TOOLTIP_discounts: 'The sum of all discounts configured in MAXMA (for example, promo codes, promotions, bonuses) on all receipts.',
    METRIC_TOOLTIP_discountsProgram: 'The sum of all discounts configured in MAXMA (for example, promo codes, promotions, bonuses) by receipts with the use of loyalty cards.',
    METRIC_TOOLTIP_roi: '<span>The Return on Investment ratio for all MAXMA instruments. The higher the %, the higher the payback.</span><b>(Revenue - Expenses) / Expenses x 100%,</b><span>where Expenses = Subscription fees, modules, mailing lists + Discounts on promo codes, promotions, bonuses</span>',
    METRIC_TOOLTIP_avgSale: 'The average value of the purchase amount for all receipts.',
    METRIC_TOOLTIP_avgSaleProgram: 'The average value of the purchase amount for all receipts of customers participating in the loyalty program.',
    METRIC_TOOLTIP_avgItemCount: 'The average number of goods or services for all receipts.',
    METRIC_TOOLTIP_avgItemCountProgram: 'The average number of goods or services in the receipts of customers participating in the loyalty program.',
    METRIC_TOOLTIP_markup: 'The difference between revenue and cost of all receipts.',
    METRIC_TOOLTIP_markupProgram: 'The difference between revenue and cost for customers participating in the loyalty program.',
    METRIC_TOOLTIP_purchaseCount: 'The number of all receipts.',
    METRIC_TOOLTIP_purchaseCountProgram: 'The number of receipts of customers participating in the loyalty program.',
    METRIC_TOOLTIP_repeatRate: 'The percentage of customers who made 2+ purchases among all buyers.',
    METRIC_TOOLTIP_engagementRate: '<span>The share of receipts with the applied loyalty card.</span><b>Count of purchases (Program) / Count of purchases x 100%</b>',
    METRIC_TOOLTIP_bonusApplied: 'The number of applied bonuses for the selected period.',
    METRIC_TOOLTIP_bonusCollected: 'The number of collected bonuses for the selected period.',
    METRIC_TOOLTIP_ltv: "<span>The company's profit received from one client for the entire time (Lifetime Value).</span><b>LTV = Lifetime × ARPU,</b><span>where newcomers for the last 30 days are not counted</span>",
    METRIC_TOOLTIP_repeatInterval: "<span>The average interval between customer purchases. Only customers who have made 2+ purchases are taken into account in the calculation.</span><b>Client's lifespan / Average purchases per client</b>",
    METRIC_TOOLTIP_lifespan: 'The number of days between the first and last purchases made using the card (Lifetime). Newcomers from the last 30 days are not counted.',
    METRIC_TOOLTIP_visitCount: 'The average number of purchases made by each customer, excluding newcomers over the past 30 days.',
    METRIC_TOOLTIP_arppu: '<span>Average Revenue Per Paying User (ARPPU) is an indicator of the average return per customer for the selected period.</span><b>ARPPU = Revenue / Number of paying customers</b>',
    METRIC_TOOLTIP_signupCount: '<div>The number of client profiles created.</div><div>Sources of registration:</div><ul><li>card issuance at the checkout;</li><li>registration on the website or application;</li><li>registration in third-party services, data from which is transmitted to MAXMA.</li></ul>',
    'Сгруппировать показатели метрик': 'Group metrics',
    'Выберите критерии группировки слева': 'Select grouping criteria on the left',
    'Применить к отчету': 'Apply to report',
    'Вложенность групп': 'Nesting of groups',
    'перетащите строку чтобы поменять вложенность': 'drag a row to change the nesting',
    'Редактирование отчета': 'Report editing',
    'введите название отчета': 'enter the name of the report',
    'Переименовать отчет': 'Rename report',
    'введите сумму': 'enter the amount',
    'Выставить счет': 'Request a new invoice',
    'Подтвердить подписку': 'Confirm subscription',
    'Открыть в браузере': 'Open in browser',
    'имя клиента не распределено по полям:': "the client's name is not distributed by fields:\n",
    'выберите место покупки': 'select a place of sale',
    'Указать сумму': 'Specify amount',
    MIN_ERROR_MESSAGE: 'at least {{amount}}',
    MAX_ERROR_MESSAGE: 'no more than {{amount}}',
    INVALID_FORMAT_ERROR_MESSAGE: 'invalid format',
    'введите промокод': 'enter the coupon',
    применить: 'apply',
    'Промокод не найден': 'Coupon not found',
    'Условия промокода не выполнены': 'The conditions of the coupon are not met',
    'выберите товар': 'select a product',
    'введите название сегмента': 'enter the segment name',
    'Цвет сегмента': 'Segment Color',
    'Количество карт': 'Number of cards',
    'введите количество карт': 'enter the number of cards',
    'Сгенерировать и выгрузить': 'Generate and export',
    Даты: 'Custom',
    'последнюю неделю': 'last week',
    'последний месяц': 'last month',
    'последний квартал': 'last quarter',
    'последний год': 'last year',
    'введите название карты': 'enter the name of the card',
    'введите идентификатор': 'enter the identificator',
    'Действует в даты c': 'Valid for Dates from',
    по: 'to',
    'введите срок действия': 'enter the validity period',
    'Для печатной карты': 'For a printable card',
    'введите товарный код': 'enter the product barcode',
    'в формате EAN-13': 'in EAN-13 format',
    'введите артикул': 'enter the SKU',
    'Тип уникального кода': 'Unique Code Format',
    'Префикс уникального кода': 'Unique Code Prefix',
    'введите префикс': 'enter the prefix',
    PRINTABLE_CARD_HELP: '<1>Learn more about\n</1><0>the printed card settings</0>',
    'Выберите категорию фильтра слева': 'Select the filter category\non the left',
    день: 'day',
    месяц: 'month',
    год: 'year',
    'Укажите хотя бы одно значение': 'Specify at least one value',
    'введите список': 'enter the list',
    кроме: 'except',
    лет: 'years',
    с: 'from',
    'Добавить точку продаж': 'Add Place of Sale',
    'Активировать опцию «Обещанный платеж» на 48 часов': 'Activate "Postponed Payment" option for 48 hours',
    'Работа сервиса приостановлена': 'The service has been suspended',
    'Работа сервиса активирована с опцией Обещанный платеж.': 'The service is activated with the "Postponed Payment" option.',
    'К пополнению баланса': 'Go to top up the account balance',
    SHARED_PROMOCODE_TYPE: 'Public',
    PERSONAL_PROMOCODE_TYPE: 'Personal',
    'Для передачи кода в рассылке или другими способами лично': 'To transmit the code in the mailing list or by other means in person',
    'Генерируемая часть кода добавляется к префиксу': 'The generated part of the code is added to the prefix',
    'Период действия с': 'Validity Dates from',
    'Условия применения': 'Usage Conditions',
    'при сумме покупки от': 'With Min Purchase Amount of',
    'в месте продажи': 'In Shops',
    'на товар': 'For Products',
    'Выдается покупателю, который передает его другу': 'Issued to the buyer, who passes it on to a friend',
    'Друг применяет промокод': 'Usage Conditions',
    'Вознаграждение другу': 'Rewards',
    'Вознаграждение рекомендателю': 'Referrer Reward',
    PROMOCODE_REFERRER_REWARDS_INFO: "Bonuses are credited <1>1 day</1> after a friend's purchase",
    PROMOCODE_REFERRER_REWARDS_INFO_other: "Bonuses are credited <1>{{count}} days</1> after a friend's purchase",
    'Рекомендатель получает': 'Reward',
    'начислять за': 'Make for',
    REWARD_NOTIFY_PHONE_MESSAGE: 'Notify referrer on getting the reward (SMS)<1/>',
    'Формат сообщения: «Имя, вам начислено N бонусов (всего на счету Y) за покупку друга по вашему промокоду: Префикс кода. Хорошего дня!»': 'Message format: "Name, you have been awarded N bonuses (total on the account Y) for the purchase of a friend by your promo code: Code Prefix. Have a nice day!"',
    'в любой день': 'any day',
    'в любой': 'on any',
    'в любую': 'on any',
    'в любое': 'on any',
    'в любые': 'on any',
    'введите название': 'enter the title',
    'рабочее название акции в платформе, клиент его не видит': 'the working name of the promotion, customers do not see it',
    Выбрать: 'Select',
    'Клиенты с картами': 'All Loyalty Members',
    'Условия акции': 'Promotional Conditions',
    'Условия по покупке': 'Conditions Of Purchase',
    'действует на': 'Is Valid For',
    чек: 'purchase',
    'любой товар': 'any product',
    'указанный товар': 'specified product',
    'Выберите товар': 'Select Product',
    'Ограничения акции': 'Promotion Restrictions',
    'Принцип работы': 'Operation Type',
    'выберите принцип': 'select the type',
    'если применилась такая акция, к чеку не применится никакая другая': 'if promotion applies, no other promotion will be applied to purchase',
    'если к строке чека применилась такая акция, акция с типом «уступает другим» к этой строке не применится': 'if applies to purchase item, promotion of type "gives way to other" won\'t be applied to it',
    'применяется на те строки чека, на которые не применена акция «вытесняет другие»': 'applies to items which doesn\'t have promotion "takes over the other"',
    'применяется к строке, только если дает большую скидку (или большее количество бонусов), чем уже примененная к этой строке акция': 'applies to item if gives more discount (or more bonuses) than other promotion applied to it',
    'применяется к строке, даже если к той же строке уже применены другие акции': 'applies to item even if there are other promotions applied',
    'Клиент участвует': 'Participation Limit',
    'неограниченное количество раз': 'unlimited',
    OFFER_ARB_TYPE_HELP: 'Mechanics how this promotion combines with others. <2>Learn more</2>',
    GROWING_DISCOUNT_START: 'for {{start}} product',
    GROWING_DISCOUNT_RANGE: 'from {{start}} to {{end}} product',
    GROWING_DISCOUNT_FURTHER: 'for {{n}} product and further',
    'Не забудьте протестировать акцию на кассе': "Don't forget to test the promotion",
    'Добавить товар': 'Add Product',
    товар: 'product',
    'все указанные товары': 'all products listed',
    NO_MORE_TIMES: '$t(no_more_times, { "count": {{count}} })',
    no_more_times_one: 'time per',
    no_more_times_other: 'times per',
    TIMES: '$t(times, { "count": {{count}} })',
    times_one: 'time',
    times_other: 'times',
    'Для настройки данного модуля обратитесь к менеджеру MAXMA': 'To configure this module,\ncontact your MAXMA manager',
    'Viber отправится только тем, кому не дойдет Push': 'Viber will only be sent to those to whom Push will not reach',
    'SMS отправится только тем, кому не дойдет Push и Viber': 'SMS will only be sent to those to whom Push and Viber will not reach',
    'SMS отправится только тем, кому не дойдет Push': 'SMS will only be sent to those to whom Push will not reach',
    'SMS отправится только тем, кому не дойдет Viber': 'SMS will only be sent to those to whom Viber will not reach',
    'Email отправится всем': 'Email will be sent to everyone',
    'Viber отправится тем, кому не дойдет Push': 'Viber will be sent to those\nto whom Push will not reach',
    'SMS отправится тем, кому не дойдет Push и Viber': 'SMS will be sent to those\nto whom Push and Viber will not reach',
    'SMS отправится тем, кому не дойдет Push': 'SMS will be sent to those\nto whom Push will not reach',
    'SMS отправится тем, кому не дойдет Viber': 'SMS will be sent to those\nto whom Viber will not reach',
    OFFSET_VALUE_MIN: '$t(offset_value_min, { "count": {{offset}} })',
    offset_value_min_one: 'minute',
    offset_value_min_other: 'minutes',
    OFFSET_VALUE_HOUR: '$t(offset_value_hour, { "count": {{offset}} })',
    offset_value_hour_one: 'hour',
    offset_value_hour_other: 'hours',
    OFFSET_VALUE_DAY: '$t(offset_value_day, { "count": {{offset}} })',
    offset_value_day_one: 'day',
    offset_value_day_other: 'days',
    OFFSET_VALUE_MONTH: '$t(offset_value_month, { "count": {{offset}} })',
    offset_value_month_one: 'month',
    offset_value_month_other: 'months',
    'Выберите тип поощрения слева': 'Select the type of incentive on the left',
    'Выберите акцию': 'Select a promotion',
    'Выберите промокод': 'Select a coupon',
    'Выберите карту': 'Select a card',
    BONUSES_EXPIRING: '$t(bonuses_expiring, { "count": {{count}} })',
    bonuses_expiring_one: 'point, expiring in',
    bonuses_expiring_other: 'points, expiring in',
    DAYS_EXPIRING: '$t(days_expiring, { "count": {{count}} })',
    days_expiring_one: 'day',
    days_expiring_other: 'days',
    MAILINGS: '{{count}} $t(mailing, { "count": {{count}} })',
    mailing_one: 'mailing',
    mailing_other: 'mailings',
    GIFT_CARDS: '{{count}} $t(giftCards, { "count": {{count}} })',
    giftCards_one: 'gift card',
    giftCards_other: 'gift cards',
    DAYS_EXPIRING_AFTER: 'after {{count}} $t(days_expiring, { "count": {{count}} })',
    действует: 'valid',
    MAILING_VALID_DAYS: '$t(mailing_valid_days, { "count": {{count}} })',
    mailing_valid_days_one: 'day since mailing',
    mailing_valid_days_other: 'days since mailing',
    'введите название рассылки': 'enter the name of the mailing',
    'рабочее название рассылки в платформе, клиент его не видит': 'the working name of the mailing, customers do not see it',
    'Согласные на рассылку': 'Who agree to the mailing',
    'Условия отправки': 'Mailing Conditions',
    'Событие, при котором рассылка автоматически отправится': 'Event at which the mailing is automatically sent',
    если: 'if',
    'отправлять при каждом наступлении события': 'send whenever an event occurs',
    'Поощрения внутри рассылки': 'Mailing Rewards',
    'Бонусы, акции, промокоды, подарочные карты': 'Points, promotions, coupons, gift cards',
    'Считать достижением цели': 'Mailing Goal',
    'Отобразится в таблицах как CR (Conversion Rate)': 'Displayed in the tables as CR (Conversion Rate)',
    'Дата отправки': 'Mailing Date',
    SEND_FOR: 'for',
    SEND_AT: 'at',
    'по часовому поясу': 'by time zone',
    'Прогнозируемые затраты': 'Projected costs',
    COST_UP_TO: 'up to',
    'в неделю': 'per week',
    Получат: 'Will be received by',
    Персонализация: 'Ad-hoc Parameters',
    'Выберите параметр': 'Select a parameter',
    'При отправке вместо него подставятся конкретные данные для клиента': 'Will be substituted with the specific data for the client when sending',
    клиент: 'customer',
    ребенок: 'child',
    промокод: 'coupon',
    'ссылка на эл.кошелек': 'wallet link',
    'код подарочной карты': 'gift card coupon',
    CUSTOMER_NAME_PARAMETER: 'Name',
    CUSTOMER_FULLNAME_PARAMETER: 'Full name',
    CUSTOMER_LASTNAME_PARAMETER: 'Last name',
    CUSTOMER_MIDDLENAME_PARAMETER: 'Middle name',
    CUSTOMER_PARAMETER: 'Full, Middle and Last name',
    'Имя ребенка в им. падеже (кто?)': "Child's name (who)",
    'Имя ребенка в дат. падеже (кому?)': "Child's name (whom)",
    'сгорает бонусов': 'points expiring',
    'всего бонусов': 'total points',
    'кол-во бонусов на счету': 'available points',
    'кол-во ожидаемых бонусов': 'expected points',
    'уровень клиента': 'client level',
    'ближайшая дата сгорания бонусов': 'nearest points expire date',
    'начислено бонусов за покупку': 'points earned for purchase',
    'Начислено бонусов': 'Bonuses accrued',
    'Сумма после скидок': 'Amount after discounts',
    'дата сгорания бонусов за покупку': 'purchase points expire date',
    'сумма покупок до следующего уровня': 'amount of purchases up to the next level',
    'сумма до следующего уровня': 'amount of purchases up to the next level',
    'отменено бонусов за возврат': 'cancelled return points',
    'начислено бонусов поощрения': 'points earned for promo',
    'дата сгорания бонусов поощрения': 'promo points expire date',
    'название товара': 'product title',
    'картинка товара': 'product image',
    'старая цена товара': 'product old price',
    'цена товара': 'product price',
    'ссылка на товар': 'product link',
    'Пример отображения': 'Example',
    'если в этом году': 'this year',
    'если в следующем году': 'next year',
    'N покупок на счетчике': 'N purchases on the counter',
    'N покупок до вознаграждения': 'N purchases before the reward',
    'дата сгорания счетчика': 'counter expire date',
    'последний пуш': 'last push message',
    'номер карты': 'card number',
    VAR_SAMPLE_CUSTOMER_NAME: 'Megan',
    VAR_SAMPLE_CUSTOMER_FULLNAME: 'Megan Gray',
    VAR_SAMPLE_CUSTOMER_LASTNAME: 'Gray',
    VAR_SAMPLE_CUSTOMER_MIDDLENAME: 'Walker',
    VAR_SAMPLE_CUSTOMER: 'Megan Walker Gray',
    VAR_SAMPLE_CHILD_NAME: 'Mia',
    VAR_SAMPLE_PRODUCT_TITLE: 'Baby Jumpsuit',
    VAR_SAMPLE_LEVEL: 'First Level',
    'Как выглядит Push': 'What Push looks like',
    'введите сообщение': 'enter the message',
    'Любые символы юникода (кириллица тоже). Рекомендуемая длина < 80 символов': 'Any unicode characters are allowed in the text, incl. international letters. Recommended length no more than 80 characters',
    'Картинка на Wallet-карте': 'Image on Wallet Card',
    'Неверный файл': 'Wrong file',
    'Показывать картинку': 'Show Image For',
    'Отправить тестовый PUSH': 'Send test PUSH',
    'Укажите номер телефона': 'Enter phone number',
    'Можете указать несколько адресов, по одному на каждую строку': 'Several email addresses can be specified, one per line',
    'Можете указать несколько номеров, по одному на каждую строку, в любом формате': 'Several numbers can be specified in any format, one per line',
    'Можете указать несколько номеров, по одному на каждую строку, в любом формате. Номер должен быть в базе клиентов, и у него должна быть установлена карта Wallet': 'Several numbers can be specified in any format, one per line. The number must be in the customer database and it must have a Wallet card installed',
    'Укажите email': 'Enter email address',
    '1125×433, до 10мб': '1125×433, up to 10Mb',
    'Любые символы юникода (кириллица тоже). Рекомендуемая длина < 400 символов': 'Any unicode characters are acceptable in the text, incl. international letters. Recommended length no more than 400 characters',
    Картинка: 'Image',
    'Квадратная, до 300кб': 'Square, up to 300Kb',
    Кнопка: 'Button Label',
    'введите текст кнопки': 'enter the button label',
    'Неверный формат': 'Wrong format',
    'ссылка из кнопки и/или картинки': 'link from button and/or image',
    'ссылка из кнопки': 'link from button',
    'Отправить тестовый Viber': 'Send test Viber',
    'Любые символы юникода (кириллица тоже). Рекомендуемая длина < 6 SMS': 'Any unicode characters are allowed in the text messages, incl. international letters. Recommended length of SMS no more than 6 parts',
    'Отправить тестовое SMS': 'Send test SMS',
    'введите тему письма': 'enter the subject',
    PRODUCTS_IN_BLOCK: '$t(products, { "count": {{count}} }) in a block',
    PRODUCTS_IN_BLOCK_HELP: 'The final email will display {{count}} $t(products, { "count": {{count}} }) in the block',
    products_one: 'product',
    products_other: 'products',
    Вставлять: 'Insert',
    'Добавить товарные рекомендации': 'Include product recommendations',
    'В редакторе будет блок «Рекомендуемые товары»': 'There will be a «Recommended Products» block in the editor',
    'Отправить тестовое письмо': 'Send test Email',
    'Тестовый Email': 'Test Email',
    'Длина:': 'Length:',
    'Выбрать дизайн или создать новый': 'Choose design\nor create new one',
    'Удалить дизайн и содержимое письма?': 'Do you really want to reset the design and the message content?',
    'Выберите шаблон письма': 'Select a email design',
    'Созданные вами': 'You have created',
    Все: 'All',
    'С чистого листа': 'From scratch',
    'У вас остался несохраненный дизайн. Восстановить его?': 'You have unsaved design. Would you like to restore it?',
    Восстановить: 'Restore',
    'Обновление...': 'Updating...',
    'Ошибка загрузки изображения': 'Image upload error',
    'Задайте получателей рассылки': 'Specify the recipients',
    'Задайте необходимые критерии': 'Specify the criteria you need',
    Совет: 'Tip',
    Лояльность: 'Loyalty',
    'регистрации в ПЛ': 'registration in the program',
    'установки карты Wallet': 'installing the Wallet card',
    'подписки на Email-рассылку': 'subscribing to the email',
    'получения Email-рассылки': 'receiving email',
    Рекомендации: 'Recommendations',
    FORM_MAILING_PROMOCODE_WARNING: "Don't forget to to insert the <2>coupon</2> parameter in",
    FORM_MAILING_GIFT_CARD_WARNING: "Don't forget to to insert the <2>gift card coupon</2> parameter in",
    FORM_MAILING_BONUSES_WARNING: 'In order not to notify clients with a zero balance when using the <2>total points</2> parameter, exclude them from the recipients',
    'Блок товаров из брошенной корзины': 'Block of items from the abandoned cart',
    'Блок товаров со сниженной ценой в избранном': 'Block of items which price has dropped',
    'Блок товаров со сниженной ценой в корзине': 'Block of items which price has dropped',
    'Соединить в цепочку': 'Connect to flow',
    Триггеры: 'Triggers',
    'Блок с товарными рекомендациями': 'Block of product recommendations',
    'Показывать в блоке': 'Show in block',
    'Добавьте блок в тело письма в редакторе': 'Add the block to the email body',
    NEW_PRODUCTS: '$t(new_products, { "count": {{count}} })',
    new_products_one: 'new product',
    new_products_other: 'new products',
    LAST_VIEWED_PRODUCTS: '$t(last_viewed_products, { "count": {{count}} })',
    last_viewed_products_one: 'last viewed product',
    last_viewed_products_other: 'last viewed products',
    BESTSELLERS: '$t(bestsellers, { "count": {{count}} })',
    bestsellers_one: 'bestseller',
    bestsellers_other: 'bestsellers',
    'по расписанию': 'On schedule',
    'каждый день': 'every day',
    'каждую неделю': 'weekly',
    'каждый месяц': 'every month',
    'каждый год': 'every year',
    SEND_ON_DAYS: 'on',
    'числа в': 'days at',
    'последний день месяца': 'last day of the month',
    'достижения нового уровня': 'promoted to a new level',
    'Сумма покупок до следующего уровня ПЛ': 'Purchase Total Up To Next Level',
    'до следующего уровня ПЛ': 'up to next level',
    'покупка от': 'purchase from',
    'покупка до': 'purchase up to',
    'не открыли письмо': "didn't open the email",
    'не перешли по ссылке в письме': "didn't follow the link in the email",
    'не купили после перехода по ссылке': 'not bought after clicking on the link',
    'выберите рассылку': 'select a mailing',
    Шаблоны: 'Templates',
    'введите количество кодов': 'enter the number of codes',
    DAYS_RANGE: '{{amount}} $t(days_range, { "count": {{count}} })',
    days_range_one: 'day',
    days_range_other: 'days',
    VIP: 'VIP',
    'Потенциальные VIP': 'Potential VIP',
    'Потенц. VIP': 'Potential VIP',
    Лояльные: 'Loyal',
    Новички: 'Newcomers',
    'Уходящие VIP': 'Leaving VIP',
    'Потенциально лояльные': 'Potential\nLoyal',
    'Потенц. лояльные': 'Potential\nLoyal',
    'Возможный отток': 'Possible\nOutflow',
    'Возм. отток': 'Possible\nOutflow',
    'VIP отток': 'VIP Outflow',
    Отток: 'Outflow',
    'Разовые клиенты': 'One-Time\nClients',
    Разовые: 'One-Time\nClients',
    Ультраотток: 'Ultraflow',
    Большая: 'Large',
    Средняя: 'Medium',
    Маленькая: 'Small',
    Недавно: 'Recently',
    Давно: 'Long Ago',
    'Очень давно': 'Very Long Ago',
    Редко: 'Rarely',
    Иногда: 'Sometimes',
    Часто: 'Often',
    'Таблица RFM в кол-ве клиентов': 'RFM Table',
    'R, дней назад': 'R, days ago',
    'F, покупки': 'F, purchases',
    'M, сумма': 'M, amount',
    Автопороги: 'Auto Thresholds',
    RFM_THRESHOLDS_TOOLTIP: 'Auto Thresholds is updated once a month.\n<2>Learn more about the calculation of thresholds</2>',
    Всего: 'Total',
    RFM_CHART_CLIENTS: 'Number of Customers',
    RFM_CHART_PURCHASES: 'Number of Purchases',
    RFM_CHART_REVENUE: 'Revenue',
    RFM_CHART_PROFIT: 'Profit',
    RFM_CHART_ARPPU: 'ARPPU',
    RFM_CHART_AVG_SALE: 'Average Sale',
    'Создать...': 'Create...',
    'Посмотреть клиентов': 'View Customers',
    'ручную рассылку': 'manual mailing',
    авторассылку: 'automated mailing',
    'Ультрабольшие чеки': 'Ultra Large Sales',
    'Ультраактивные покупатели': 'Ultra Active Customers',
    'Возможный фрод': 'Possible Fraud',
    Оптовики: 'Wholesalers',
    RFM_SEGMENT_DESCRIPTION_VIP: 'VIP super-loyal customers buy a lot and made their last purchase recently. It is important to retain them, to motivate them to increase their purchase figures. The main objectives of the work: to retain and increase the volume of the group by increasing the loyalty level of the rest of the client base, as well as continue to improve purchase performance.',
    RFM_SEGMENT_DESCRIPTION_potentialVIP: 'Potentially super-loyal customers who are close to the transition to the group of super-loyal VIP customers. They also have a positive attitude towards the brand, buy a lot, and made their last check recently. The growth point is a small and average amount of purchases, not enough to be defined as VIP. It is important to motivate such customers to increase the amount of purchases by increasing the average check or frequency of visits.',
    RFM_SEGMENT_DESCRIPTION_loyal: "Loyal brand customers. Growth points: average number of purchases and average or small amount. Can potentially become super-loyal VIPs, provided they either make repeat purchases with an increase in average check, or multiply the frequency of purchases on their standard check. It's important to motivate such customers to make repeat purchases as the transaction amount grows.",
    RFM_SEGMENT_DESCRIPTION_newcomers: "New brand customers who have recently joined the loyalty program and made a purchase. Growth point: still small number of purchases. Can become loyal and super-loyal VIP customers if they continue to make purchases with regular frequency. When registering, it's important to encourage them to participate in a loyalty program (welcome message) - to make a second purchase. Next, these customers need to be introduced to the brand and motivated to make their next repeat purchases.",
    RFM_SEGMENT_DESCRIPTION_leavingVIP: "Super-loyal VIP customers who haven't made a purchase. Feature: either customers have a low frequency of purchases while Loyalty retained, or customers have changed their purchasing behavior with the risk of going into outflow and losing loyalty. In order to return to the brand is important to motivate them to make a repeat purchase. The main task - is to return to the VIP group by means of an interesting offer and communication with encouragement for the next purchase.",
    RFM_SEGMENT_DESCRIPTION_potentialLoyal: "Potentially loyal brand customers who haven't made a purchase in a relatively long time. Feature: either customers have low frequency of purchases while maintaining loyalty, or customers have changed their purchasing behavior with the risk of leaving in outflow and loss of loyalty. In the future they can become a loyal group if they increase frequency of purchases. The main task is to return to the group of loyal through an interesting offer and communication with an incentive for the next purchase.",
    RFM_SEGMENT_DESCRIPTION_outflowPossible: 'New brand customers who are at risk of falling into outflow because of the age of their last purchase. Growth points: still a small number of purchases and the age of the last purchase. In the long run, they can become loyal, provided they increase the frequency of purchases. The main goal is to bring them back and move them into the loyal group through an interesting offer and communication with an incentive for the next purchase.',
    RFM_SEGMENT_DESCRIPTION_outflowVIP: "Ultra-loyal VIP customers who haven't made a purchase in a long time and are considered outflow. Feature: Customers were previously ultra-loyal to the brand, but have now changed their buying behavior with a departure into outflow and loss of loyalty. To return to the brand, it is important to motivate them to make a repeat purchase. The main task is to return to the VIP group as part of the reactivation activities through an interesting offer and communication with a great incentive for the next purchase. If there is no reaction to the chain of messages - remove from communications.",
    RFM_SEGMENT_DESCRIPTION_outflow: "Loyal customers who haven't made a purchase in a long time and are considered outflow. Feature: Customers were previously loyal to the brand by making repeat purchases, but have now changed their buying behavior with a departure into outflow and loss of loyalty. To get back to the brand, it is important to motivate them to make a repeat purchase. The main task is to return to the group of loyal or VIP in the reactivation activities through an interesting offer and communication with a medium incentive for the next purchase. If there is no reaction to the chain of messages - remove from communications.",
    RFM_SEGMENT_DESCRIPTION_oneTimeClients: "New brand customers who haven't made a repeat purchase in a very long time And are considered outflow. May be one-time visitors for reasons beyond the brand's control, beyond the brand's control. The main goal is to try to bring them back with a small number of touches as reactivations with minimal incentive through an interesting offer on the next purchase. At lack of response to the message chain - remove from communications.",
    RFM_SEGMENT_DESCRIPTION_ultraflow: 'Customers who made their last purchase too long ago.',
    RFM_SEGMENT_DESCRIPTION_ultraLargeSales: 'Customers who make purchases with a high average check - non-standard for the main base. Recommendations are used to work with clients in this group according to the assigned RFM segment.',
    RFM_SEGMENT_DESCRIPTION_ultraActiveCustomers: 'Customers who have committed a large number of checks - non-standard for the main base. To work with clients of this group it is recommended to allocate a separate segment and use mechanics to increase the average check.',
    RFM_SEGMENT_DESCRIPTION_possibleFraud: "Customers who made a large number of purchases for a large amount of money with a short interval between checks - as opposed to the main base of customers. This anomaly is considered a risk of occurrence of fraud. It is recommended to block the client's profile on the platform and check with the SB. If there is no fraudulent activity, consider the client a VIP and use recommendations in accordance with the RFM analysis.",
    RFM_SEGMENT_DESCRIPTION_wholesalers: 'Customers who have made a large number of checks for a large amount relative to the characteristics of the main base - the behavior is considered similar to the wholesale customers. For work with clients of this group it is recommended to allocate a separate segment and to use mechanics on holding within the segment with increase of frequency of purchases, and also development of personalized actions and offers depending on features of the bought goods and volumes of purchase.',
    'настройте автоматическую рассылку на день рождения клиента': "set up an automated mailing for the customer's birthday",
    'используйте ручные рекламные и информационные рассылки (новая коллекция, распродажи, информация о бренде и др.)': 'use manual advertising and information mailings (new collection, sales, brand information, etc.)',
    'Исключите сегмент из ручных рекламных и информационных рассылок': 'Exclude the segment from manual advertising and information mailings',
    SET_UP_AUTO_MAILINGS: 'Set up {{count}} $t(auto_mailing, { "count": {{count}} })',
    auto_mailing_one: 'automated mailing',
    auto_mailing_other: 'automated mailings',
    Дополнительно: 'Additionally',
    BURNING_REWARD_REMINDER: 'reminder of the reward burning after {{count}} $t(days_expiring, { "count": {{count}} })',
    USE_REWARD_MESSAGE: 'Use {{rewardType}} reward that burns out {{count}} $t(days_expiring, { "count": {{count}} }) after sending.',
    среднее: 'medium',
    'минимальное или среднее': 'minimal or medium',
    минимальное: 'minimal',
    крупное: 'large',
    'среднее или крупное': 'medium or large',
    MOVE_TO_THE_SEGMENT: 'when a customer moves to the «{{segmentName}}» segment',
    IN_THE_SEGMENT: 'if a customer is in the «{{segmentName}}» segment for {{count}} $t(days_expiring, { "count": {{count}} })',
    IN_THE_SEGMENT_WITH_REPEAT: 'if a customer is in the «{{segmentName}}» segment for {{count}} $t(days_expiring, { "count": {{count}} }) with a repeat sending once every {{countRepeat}} $t(days_expiring, { "count": {{countRepeat}} })',
    'Настройте welcome-рассылку': 'Set up welcome-mailing',
    'при регистрации клиента с приветствием от бренда и подарочным начислением': 'at customer registration with a greeting from the brand and a gift accrual',
    'Исключение сегмента из автоматических рассылок': 'Excluding the segment from automated mailings',
    'Периодическая реактивация через массовые ручные рассылки с контролем их рентабельности': 'Periodic reactivation through mass manual mailings with monitoring of their profitability',
    'Механики для увеличения частоты покупок (акции с предложением, ограниченным по времени)': 'Mechanisms to increase the frequency of purchases (promotions with time-limited offers)',
    'Информационные рассылки': 'Information mailings',
    'Стандартные автоматические рассылки: день рождения и праздники': 'Regular automated mailings: birthdays and holidays',
    'Рекомендации в соответствии с присвоенным маркером RFM': 'Recommendations according to the assigned RFM marker',
    'Механики для увеличения среднего чека (предложение акций 1+1, комплементарных предложений и т.д.)': 'Mechanics to increase the average sale (offering 1+1 promotions, complementary offers, etc.)',
    'Блокировка пользователей, исключение из всех рассылок и передача на проверку в СБ': 'Blocking customers, excluding them from all mailings and sending them to the Security Service for verification',
    'При ошибке — возвращение в сегмент VIP': 'On error — return to the «VIP» segment',
    'Изучение сегмента, разработка персональных акций': 'Study of the segment, development of personal promotions',
    'Стандартные рекламные рассылки: с выгодным предложением / акцией / новостью о новом товаре или интересной товарной группе': 'Regular promotional mailings: with a profitable offer / promotion / news about a new product or interesting product group',
    'Смотреть метрики в отчете': 'View metrics in a report',
    'А вы знали, что работа с RFM-сегментами поможет достичь конверсии до 80%?': 'Did you know that working with RFM segments can help you achieve conversions of up to 80%?',
    'Протестируйте бесплатно — запросите демо у вашего менеджера': 'Test it for free - request a demo from your manager',
    TRY_RFM_SEGMENTS: 'Try RFM segments',
    REGULAR_SEGMENTS: 'Regular',
    'RFM сегменты': 'RFM segments',
    'Автоматический расчет сегментов по покупательскому поведению.': 'Automatic calculation of segments by customer behavior.',
    'Про RFM в базе знаний': 'Learn more about RFM',
    'Попробуйте изменить фильтры': 'Try changing filters',
    'Недостаточно данных': 'Not enough data',
    'MAXMA накапливает историю сегментов и скоро выведет график': 'MAXMA is accumulating segment history and will soon display the chart',
    'Загрузите данные клиентов, чтобы увидеть распределение по сегментам': 'Upload customer data to see segmentation',
    'Всего покупок (для RFM)': 'Total Purchases (RFM)',
    '(Выделить все)': '(Select all)',
    'Не задано': 'Not set',
    'Покупают часто и много': 'Buy often and a lot',
    'Последняя покупка была недавно': 'Last purchase was recently',
    'Покупали часто и много': 'Used to buy often and a lot',
    'Последняя покупка была очень давно': 'Last purchase was a long time ago',
    'Последняя покупка была давно': 'Last purchase was a long time ago',
    'Покупают довольно часто с небольшими или средними чеками': 'Buy quite often with small to medium checks',
    'Покупают иногда с небольшими или средними чеками': 'Buy occasionally with small to medium checks',
    'Покупали довольно часто с небольшими или средними чеками': 'Used to buy quite often with small to medium checks',
    'Сделали мало покупок': 'Made few purchases',
    'Последняя покупка была слишком давно': 'Last purchase was too long ago',
    Данные: 'Data',
    'Всего строк': 'Lines Total',
    PURCHASES_RANGE: '{{amount}} $t(purchases_range, { "count": {{count}} })',
    purchases_range_one: 'purchase',
    purchases_range_other: 'purchases',
    'По всем клиентам': 'For all customers',
    'Пожалуйста, установите начальную дату не ранее 8 июля 2023': 'Please set the start date no earlier than July 8, 2023',
    RFM_CHART_SET_DATE: 'Set the start date',
    'Я хочу подключить модуль RFM': 'I want to turn on the RFM module',
    'потрачено на покупку': 'spent on purchases',
    'отменено за возврат': 'canceled for a refund',
    'начислено оператором': 'granted by the operator',
    'списано оператором': 'charged by the operator',
    'Причина блокировки': 'Reason for blocking',
    'Обновить уровни лояльности на основе суммы покупок': 'Update loyalty levels based on purchase amount',
    'Номинал подарочной карты': 'Gift Card',
    'Увеличить/уменьшить количество бонусов на указанное значение': 'Increase/decrease the amount of bonuses by the specified value',
    'Задать новое количество бонусов': 'Set a new number of bonuses',
    IMPORT_LINES: '{{amount}} $t(import_lines, { "count": {{count}} })',
    import_lines_one: 'line',
    import_lines_other: 'lines',
    LINES: '$t(lines, { "count": {{count}} })',
    lines_one: 'line',
    lines_other: 'lines',
    логин: 'login',
    пароль: 'password',
    'Настройте соответствие полей': 'Set up field matching',
    'Чтобы не загружать столбец из файла, выберите пункт «не загружать»': 'To not load a column from a file, select "Do not load"',
    'Определите хотя бы одно из обязательных полей: номер телефона, номер карты или внешний идентификатор': 'Define at least one of the required fields: Phone Number, Card No or External ID',
    'Определите обязательное поле: уникальный код': 'Define the required field: Unique Code',
    'Определите обязательное поле: уникальный код карты': 'Define the required field: Unique card code',
    'Срок действия в днях': 'Validity Dates',
    'Уведомить клиента?': 'Notify customer',
    'Уникальный код карты': 'Unique card code',
    'Уникальный код': 'Unique Code',
    'Порядковый номер карты': 'Card ordinal number',
    'Оставшаяся сумма на карте': 'Remaining card amount',
    'Начальная сумма на карте': 'Initial card amount',
    'Кол-во применений': 'Num of applications',
    'Активирована?': 'Activated?',
    'Сгорела?': 'Expired?',
    'Дата сгорания': 'Expiration Date',
    'Заблокирована?': 'Blocked?',
    'Дата блокировки': 'Block Date',
    Активация: 'Activation',
    Сгорание: 'Expiration',
    Блокировка: 'Blocking',
    Продажа: 'Sale',
    'Уникальный ID': 'Unique ID',
    'Код торговой точки': 'Shop code',
    'Дата продажи': 'Purchase date',
    'Имя кассира': 'Cashier name',
    'Код кассира/табельный номер': 'Cashier code',
    'Номер смены': 'Session number',
    'Номер чека': 'Receipt number',
    'Полная сумма чека': 'Total purchase amount',
    'Полная скидка': 'Total discount',
    'Оплаченная сумма': 'Paid amount',
    'Бонусов списано на чек': 'Bonuses applied to purchase',
    'Бонусов начислено за чек': 'Bonuses earned from purchase',
    'Строка чека': 'Sale Row',
    'Идентификатор/код': 'Product ID or code',
    'Идентификатор/код категории': 'Category ID or code',
    'Наименование категории': 'Category name',
    'Цена за единицу': 'Price (for a unit)',
    'Закупочная цена за единицу': 'Buying price (for a unit)',
    'Бонусов списано на строку': 'Bonuses applied to row',
    'Бонусов начислено за строку': 'Bonuses earned from row',
    'Определите обязательное поле: уникальный ID, код торговой точки, дата продажи': 'Define the required field: Unique ID, Shop code, Purchase date',
    'Определите хотя бы одно из обязательных полей: цена за единицу, сумма строки или сумма чека': 'Define at least one of the required fields: Price (for a unit), Paid amount (for a row), Paid amount (for the sale)',
    DAYS_MESSAGE: '$t(days_message, { "count": {{count}} })',
    days_message_one: 'day',
    days_message_other: 'days',
    'Скоро день рождения': 'Birthday coming up',
    Скоро: 'Soon',
    'Пример цепочки': 'Example of a flow',
    Записаться: 'Sign up',
    'Что дадут цепочки?': 'What will the flows give?',
    'Создавать авторассылки будет еще проще!': 'Creating auto-mail\nwill be even easier!',
    'Есть идеи?': 'Any ideas?',
    'Если у вас есть пожелания к цепочкам или опыт работы с автоматизациями — самое время записаться на созвон:': "If you have any suggestions for chains or experience with automation, it's time to sign up for a call:",
    'все триггеры и условия в новом формате': 'all triggers and conditions <strong>in the new format</strong>',
    'новые связки между рассылками — паузы и условия': '<strong>new links</strong> between mailings — pauses and conditions',
    'полную картину всех развилок для кампании': 'a complete picture of <strong>all the forks</strong> for the campaign',
    'возможность работы над несколькими рассылками не выходя из редактора': 'ability to work on multiple mailings <strong>without leaving the editor</strong>',
    'Введите нули, если нужна текущая дата/сегодня': 'Enter zeros if the current date/today is needed',
    BEFORE: 'before',
    AFTER: 'after',
    'Загрузка...': 'Loading...',
    согласен: 'agreed',
    'От кого': 'From',
    Приложение: 'App',
    Счетчик: 'Counter',
    счетчик: 'counter',
    'Название счетчика': 'Counter Name',
    COUNTER_NAME_HELP: 'Give the counter a name that will distinguish it from counters in other possible promotions and display on customer checks',
    'например, фишки кофе': 'coffee counter, for example',
    Купить: 'Buy',
    Считать: 'Count',
    покупки: 'purchases',
    Период: 'Period',
    'неограниченный период': 'any period',
    COUNTER_APPLY_ON_PRODUCT_HELP: 'Reward will be given for the N-th purchased <2>product</2> or <2>set of products</2> — regardless of the check. For example, for every 3rd product bought in several checks — a product as a gift',
    COUNTER_APPLY_ON_PURCHASE_HELP: 'Reward will be given for the N-th <2>purchase</2> with specified characteristics. For example, for every 3rd purchase above 2000 {{currency}} — a product as a gift',
    COUNTER_PERIOD_IN: 'in',
    IN_MONTHS: '$t(in_months, { "count": {{count}} })',
    in_months_one: 'month',
    in_months_other: 'months',
    IN_DAYS: '$t(in_days, { "count": {{count}} })',
    in_days_one: 'day',
    in_days_other: 'days',
    COUNTER_FILTER_HELP: 'To customize the promotions "Nth item or purchase as a gift". <2>Customization instructions</2>',
    'Счетчик можно пополнять все время пока действует акция': 'The counter can be refilled for as long as the promotion is active',
    COUNTER_RESET_HELP_DAY: 'A {{count}}-day outstanding counter will be reset',
    COUNTER_RESET_HELP_MONTH: 'A {{count}}-month outstanding counter will be reset',
    COUNTER_REWARD_HELP_PURCHASE: '$t(counter_reward_help_purchase_ordinal, { "count": {{count}} })',
    counter_reward_help_purchase_ordinal_one: 'Rewards will be given for the {{count}}st purchase.',
    counter_reward_help_purchase_ordinal_two: 'Rewards will be given for the {{count}}nd purchase.',
    counter_reward_help_purchase_ordinal_few: 'Rewards will be given for the {{count}}rd purchase.',
    counter_reward_help_purchase_ordinal_other: 'Rewards will be given for the {{count}}th purchase.',
    COUNTER_REWARD_HELP_PRODUCT: '$t(counter_reward_help_product_ordinal, { "count": {{count}} })',
    counter_reward_help_product_ordinal_one: 'Reward will be given for the {{count}}st product.',
    counter_reward_help_product_ordinal_two: 'Reward will be given for the {{count}}nd product.',
    counter_reward_help_product_ordinal_few: 'Reward will be given for the {{count}}rd product.',
    counter_reward_help_product_ordinal_other: 'Reward will be given for the {{count}}th product.',
    'бесплатный товар': 'free product',
    Счетчики: 'Counters',
    PRODUCT_RANGE: '$t(product_range, { "count": {{count}} })',
    product_range_one: 'product',
    product_range_other: 'products',
    PURCHASE_RANGE: '$t(purchase_range, { "count": {{count}} })',
    purchase_range_one: 'purchase',
    purchase_range_other: 'purchases',
    'Нет карты': 'No card',
    'Создать с нуля': 'Create from scratch',
    COPY_WALLET_CARD: 'Copy from\n{{name}}',
    'Уровни карт': 'Card Levels',
    Геопуши: 'Geo Push',
    Сведения: 'Details',
    Шапка: 'Head',
    Баннер: 'Banner',
    Поля: 'Fields',
    Уведомления: 'Notifications',
    'Обратная сторона': 'Back Side',
    Цвета: 'Colors',
    логотип: 'logo',
    'заголовок карты': 'card title',
    'название карты': 'card name',
    поле: 'field',
    'главное поле': 'main field',
    'с обычной картинкой': 'only image',
    'с текстом': 'image and text',
    'Текст на баннере': 'Banner Text',
    'верхняя строка': 'top row',
    'нижняя строка': 'bottom row',
    'Баннер в сведениях': 'Backside Banner',
    название: 'title',
    значение: 'value',
    'Добавить ряд полей': 'Add more fields',
    WALLET_CARD_MESSAGE_EXAMPLE: 'Victoria, congratulations! Your gift: 500 bonuses, until 31.12',
    'Название бренда': 'Brand Name',
    'Название карты для Apple': 'Title of an Apple card',
    'Добавить блок': 'Add block',
    Ссылки: 'Contacts',
    'Добавить ссылку': 'Add contact',
    Готово: 'Done',
    'Логотип в шапку для Apple': 'Logo for Apple',
    'Логотип в шапку для Google': 'Logo for Google',
    'Баннер для Apple': 'Banner for Apple',
    'Баннер для Google': 'Banner for Google',
    'и для Apple': 'for Apple too',
    'и для Google': 'for Google too',
    'Ошибка обработки файла': 'File processing error',
    'Идет загрузка...': 'Loading...',
    'В конечном сообщении в них автоматически подставляются конкретные данные для клиента': 'In the final message, customer-specific data is automatically substituted into them',
    Баркод: 'Barcode',
    'Без кода': 'No code',
    'Опубликовать все карты': 'Publish all cards',
    'Настройте уровни всех карт и геопуши': 'Create cards of all levels and geo push items',
    'Не забудьте про геопуши': "Don't forget geo push items",
    'Все настроено!': "It's all set up!",
    'Изменения в картах и геопушах': 'There are changes to cards and geo push items',
    'Изменения в картах': 'There are changes to cards',
    'Изменения в геопушах': 'There are changes to geo push items',
    'Карты опубликованы': 'Cards published',
    'Не удалось опубликовать карты': 'Failed to publish cards',
    'Карты, уже установленные у клиентов, получат обновление в течение суток': 'Maps already installed with customers\nwill receive the update within 24 hours',
    'Пожалуйста, заполните поля': 'Please fill in the form fields',
    'обязательное поле': 'required field',
    'Геопуш изменен': 'Geo push item updated',
    'Удалить геопуш?': 'Delete geo push item?',
    'Геопуш удален': 'Geo push item deleted',
    Геопуш: 'Geo Push Item',
    'Геопуш создан': 'Geo push item created',
    'Выберите счетчик': 'Select a counter',
    'Выберите формат отображения': 'Select a date format',
    'Установка карты на телефон': 'Installing the card on the phone',
    'Выберите клиента, чтобы получить ссылку на его карту': 'Select a customer to get a link to their card',
    'Отсканируйте QR-код': 'Scan the QR code',
    'Или отправьте ссылку на телефон, чтобы открыть ее в мобильном браузере:': 'Or send the link to your phone:',
    'Скопировать ссылку': 'Copy link',
    'Скопировано в буфер обмена': 'Copied to clipboard',
    уровень: 'level',
    'Откройте ссылку в браузере': 'Open the link in your browser',
    DATE_FORMAT_LABEL: 'Date Format',
    'Выпуск карт': 'Card issuance',
    'Карта сохранена': 'Card saved',
    'логотип Google': 'Google card logo',
    'заголовок карты Google': 'Google card title',
    'название карты Google': 'Google card name',
    заголовок: 'title of an Apple card',
    'логотип уведомления': 'notification title',
    'название бренда': 'brand name',
    'дата сгорания': 'expired at',
    'Бонусная карта': 'Bonus card',
    'Веб-сайт': 'Website',
    'Получайте кэшбек 5% со всех покупок и оплачивайте ими до 50% от стоимости товаров!': 'Get a 5% cashback on all purchases and use it to pay up to 50% of the cost of goods!',
    Старт: 'Start',
    Каскад: 'Cascade',
    TRIGGER_TYPE_SEGMENT_JOIN: 'Getting into the segment',
    TRIGGER_TYPE_SEGMENT_LEAVE: 'Leaving the segment',
    TRIGGER_TYPE_PURCHASE: 'Making a purchase',
    TRIGGER_TYPE_BONUS_ACTIVATE: 'Points activation',
    TRIGGER_TYPE_RETURN: 'Returning goods',
    TRIGGER_TYPE_ABANDONED_CART: 'Leaving website (abandoned cart)',
    TRIGGER_TYPE_PRODUCT_VIEW: 'Leaving website (viewed products or categories)',
    TRIGGER_TYPE_PRICE_IN_CART_DROPPED: 'Price dropped for a cart item',
    TRIGGER_TYPE_PRICE_IN_FAVORITE_DROPPED: 'Price dropped for a favorite item',
    TRIGGER_TYPE_BIRTHDAY: 'Birthday',
    TRIGGER_TYPE_CHILD_BIRTHDAY: 'Child’s birthday',
    TRIGGER_TYPE_BONUS_EXPIRING: 'Points expire',
    TRIGGER_TYPE_REGISTER_CLIENT: 'Registration in the program',
    TRIGGER_TYPE_REGISTER_WALLET_CARD: 'Installing the Wallet card',
    TRIGGER_TYPE_SUBSCRIBE_EMAIL: 'Subscribing to the email',
    TRIGGER_TYPE_SCHEDULE: 'On schedule',
    TRIGGER_TYPE_LEVEL_UP: 'Promoted to a new level',
    TRIGGER_TYPE_EMAIL_DELIVERED: 'Receiving email',
    'Распустить цепочку': 'Delete Flow',
    Распустить: 'Delete',
    'Цепочка удалена': 'Flow deleted',
    'Цепочка изменена': 'Flow updated',
    'Название цепочки': 'Flow title',
    'Выйти из цепочки': 'Exit',
    'Сохранить цепочку': 'Save Flow',
    'Не удалось скопировать дизайн карты': 'Failed to copy card',
    'Чтобы изменить триггер, рассылку нужно открепить от цепочки.': 'To change the trigger, the mailing has to be detached from the flow.',
    'Остальные изменения будут отражены в цепочке': 'Other changes will be reflected in the flow',
    DELETE_FLOW_TITLE: 'Delete flow\n<1>{{title}}</1>?',
    DELETE_FLOW_DESCRIPTION: '<0>{{title}}</0> will disappear from the list of flows, but the mailings will continue to work unchanged',
    FLOWS_PANEL_HEADER: '{{amount}} $t(flows, { "count": {{count}} })',
    flows_one: 'flow',
    flows_other: 'flows',
    'Скоро здесь можно будет полностью редактировать и создавать цепочки на холсте': "Soon you'll be able to fully edit and create flows on canvas here",
    'Соединение в цепочку': 'Linking into a flow',
    'Выберите рассылки с одним триггером, чтобы увидеть их на холсте': 'Select mailings with the same trigger to see them on canvas',
    'Пока не создано ни одной цепочки': 'No flows have been created yet',
    'Соберите рассылки с одним триггером в цепочку, чтобы увидеть их на холсте': 'Link mailings with the same trigger into a flow to see them on canvas',
    'Добавить цепочку': 'Add flow',
    'Собрать из рассылок': 'Link mailings into a flow',
    'Создать цепочку': 'Create flow',
    FLOWS_BETA_TOOLTIP: 'An early version of flows with limited functionality. <2>Learn more about the Beta version</2>',
    Фильтр: 'Filter',
    'Условие старта': 'Start condition',
    'Открыл письмо': 'Opened Email',
    'Перешел по ссылке': 'Followed Link',
    'Сделал покупку': 'Made Purchase',
    '1 раз': 'once',
    'Отправлять только один раз на клиента': 'Send only once per customer',
    DELETE_MAILING_TITLE: 'Delete mailing\n<2>{{name}}</2>?',
    DELETE_MAILING_DESCRIPTION: 'This mailing will be removed from both\nAutomated Mailings and Flows',
    MAILING_GOAL_DAYS_TO: '$t(mailing_goal_days_to, { "count": {{count}} })',
    mailing_goal_days_to_one: 'day since mailing',
    mailing_goal_days_to_other: 'days since mailing',
    'в течение': 'within',
    Перетоки: 'Movements',
    Переток: 'Movement',
    Откуда: 'From',
    Куда: 'To',
    Ушло: 'From',
    Пришло: 'To',
    'В сегмент': 'To Segment',
    'Из сегмента': 'From Segment',
    'Стали более лояльны': 'Became more loyal',
    'Стали менее лояльны': 'Became less loyal',
    'Активируйте хотя бы один из каналов': 'Activate at least one of the channels',
    'до 1 Мб': 'up to 1 MB',
    'Можете указать несколько номеров, по одному на каждую строку, в любом формате. Номер должен быть в базе клиентов, а у клиента должен быть начат диалог с Telegram-ботом': 'Several numbers can be specified in any format, one per line. The number must be in the customer database and and the client must have a dialog with the Telegram bot started',
    'Тестовый Telegram': 'Test Telegram',
    Отправить: 'Send',
    'Отправить тестовое сообщение': 'Send test message',
    'Неверный размер': 'Wrong image size',
    'со счетчиком': 'counter',
    'Баннер выведет не более 60-ти значков': 'The banner will display a maximum of 60 icons',
    'Осталось накопить': 'Left to save up',
    'из чека': 'of the receipt',
    'товары из чека': 'products',
    'покупки, содержащие': 'purchases with',
    LOGIN_2FA_DESCRIPTION: 'We sent a security code to your email {{ email }} because you logged in from a new device.',
    'Код действует': 'The code is valid for',
    'Войти в другой аккаунт': 'Login to another account',
    // События пользователей страницы clients/events
    SENDING_CLICK: 'Clicked on the <a href="{{linkHref}}" target="_blank">link</a> in the email <a href="{{emailHref}}" target="_blank">{{emailName}}</a>',
    SENDING_VIBER: 'Sent by Viber <a href="{{linkHref}}" target="_blank">{{mailingName}}</a>',
    SENDING_SMS: 'Sent by SMS <a href="{{linkHref}}" target="_blank">{{mailingName}}</a>',
    SENDING_EMAIL: 'Sent by email <a href="{{linkHref}}" target="_blank">{{mailingName}}</a>',
    PURCHASE_RETURN: 'Сделал <a href="{{linkHref}}" target="_blank">возврат</a>',
    FRIEND_PURCHASE: 'A friend made a <a href="{{linkHref}}" target="_blank">purchase</a>',
    PURCHASE_REVERT: 'Cancellation of the<a href="{{linkHref}}" target="_blank"> purchase</a>',
    ACCRUED_TO_EMPLOYEES: 'Accrued by an employee:\n{{operatorName}}',
    WRITTEN_OFF_BY_EMPLOYEE: 'Written off by an employee:\n{{operatorName}}',
    DELETED_BY_EMPLOYEE: 'Deleted by an employee:\n{{operatorName}}',
    BLOCKED_BY_EMPLOYEE: 'Blocked by an employee:\n{{operatorName}}',
    UNBLOCKED_BY_EMPLOYEE: 'Unblocked by an employee:\n{{operatorName}}',
    UPDATED_BY_EMPLOYEE: 'Changed by an employee:\n{{operatorName}}',
    CREATED_BY_EMPLOYEE: 'Created by an employee:\n{{operatorName}}',
    FRIENDS_BONUS: 'Friend: {{referralPurchaseClientName}}',
    LEAVE_SEGMENT: 'Got out of {{segmentName}}',
    LEVEL_UP: 'Moved to the next level {{level}}',
    CREATED_WALLET_CARD: 'Installed a Wallet card, level X, {{family}}',
    DELETED_WALLET_CARD: 'Deleted the Wallet card, {{family}}',
    'Создал заказ': 'Created an order',
    'Собрано на покупку': 'Collected for purchase',
    'Применено на покупку': 'Applied for purchase',
    'Счётчик предложений увеличен': 'The offer counter has been increased',
    'Счётчик предложений истёк': 'The offer counter has expired',
    'Выдан купон на предложение': 'A coupon has been issued for the offer',
    'Срок действия истек': 'The validity period has expired',
    'Бонусы отменены': 'Bonuses have been canceled',
    'Начислены бонусы': 'Bonuses have been accrued',
    'Отозваны бонусы': 'Bonuses have been withdrawn',
    'Восстановлены бонусы': 'Bonuses have been restored',
    'Отменены бонусы за отменённую покупку': 'Cancelled bonuses for cancelled purchases',
    'Возвращены применённые бонусы за отменённую покупку': 'The applied bonuses for the cancelled purchase have been refunded',
    'Собрано вознаграждение за покупку друга': 'A reward has been collected for the purchase of a friend',
    'Присоединиться к сегменту': 'Join a segment',
    Создание: 'Creation',
    'Изменены данные': 'The data has been changed',
    'Изменены данные (клиентом)': 'Data changed (by the client)',
    Удаление: 'Removal',
    Разблокирован: 'Unblocked',
    'Создание (клиентом)': 'Creation (by the client)',
    'Удаление (клиентом)': 'Deletion (by the client)',
    Другое: 'Other',
    'Добавьте шаг': 'Add a step',
    'Пароли должны совпадать': 'Passwords must match',
    'Пароль успешно изменен': 'The password has been successfully changed',
    'Ссылка актуальна 24 часа': 'The link is valid for 24 hours',
    'Вернуться на страницу входа': 'Go back to the login page',
    'новый пароль': 'new password',
    'повторите пароль': 'repeat password',
    'Скопировать данные для входа': 'Copy the login information',
    'задает пользователь': 'set by the user',
    'задать вручную': 'set manually',
    'без изменений': 'without changes',
    'отправить ссылку для обновления': 'send an update link',
    'На почту пользователя отправится ссылка на создание пароля': "A link to create a password will be sent to the user's email",
    'задать новый вручную': 'set a new one manually',
    Заказы: 'Orders',
    'Дата заказа': 'Date',
    'Сумма заказа': 'Order Amount',
    'Место, где был совершен заказ': 'Place of order',
    Заказ: 'Order',
    'Отменить заказ': 'Cancel Order',
    'Подтвердить заказ': 'Confirm Order',
    'Отменить заказ?': 'Cancel order?',
    'Отменить заказ клиента {{clientName}}?': 'Cancel the order of the client\n{{clientName}}?',
    Отменён: 'Cancelled',
    'Заказ отменён': 'Order cancelled',
    'Заказ подтверждён': 'Order confirmed',
    'Не выкуплен': "Hasn't been redeemed for",
    'Вы офлайн': "You're offline",
    'Расчёты предварительные': 'Calculations are preliminary',
    'Окончательный расчёт производится при подтверждении заказа': 'Final calculation is made upon confirmation of order',
    Завершенные: 'Completed',
    'В работе': 'In processing',
    Отмененные: 'Cancelled',
    Группа: 'Group',
    'вне групп': 'outside groups',
    'Создать группу': 'Create a group',
    'Сохранить группу': 'Save group',
    'Удалить группу?': 'Delete the group?',
    'Содержащиеся точки останутся в системе вне групп': 'The contained places of sale will remain\nin the system outside of the groups',
    'Все точки': 'All Places',
    'Доступ с...': 'Access from...',
    Истёк: 'Expired',
    SHOPS_PLURAL: '{{amount}} $t(shops_plural, { "count": {{count}} })',
    shops_plural_one: 'place',
    shops_plural_other: 'places',
    GROUPS_PLURAL: '{{amount}} $t(groups_plural, { "count": {{count}} })',
    groups_plural_one: 'group',
    groups_plural_other: 'groups',
    'Пользователь будет иметь доступ к данным только по перечисленным точкам продаж': 'User will only have access to data for the listed places',
    'Клиент попал в сегмент': 'Customer got into the segment',
    'Попал в сегмент': 'Customer got into the segment',
    'Попал в RFM-сегмент': 'Customer got into the RFM-segment',
    'Клиент вышел из сегмента': 'Customer has left the segment',
    'Покинул сегмент': 'Customer has left the segment',
    'Покинул RFM-сегмент': 'Customer has left the RFM-segment',
    'Клиент зарегистрировался в Программе Лояльности': 'Customer has signed up for the Loyalty Program',
    'Клиент перешел на новый уровень': 'Customer has promoted to a new level',
    'Клиент установил карту Wallet': 'Customer has installed a wallet card',
    'Клиент совершил покупку': 'Customer has made a purchase',
    'Клиент сделал возврат': 'Customer has made a return',
    'Активировались бонусы': 'Points have been activated',
    'Сгорают бонусы': 'Points expire',
    'Клиент бросил корзину': 'Customer dropped off a shopping cart',
    'Брошенный просмотр каталога': 'Abandoned catalog view',
    'Снизилась цена в брошеной корзине': 'Price dropped for a cart item',
    'Снизилась цена в избранном': 'Price dropped for a favorite item',
    'Клиент подписался на Email-рассылку': 'Customer has subscribed to the email',
    'Наступает День рождения': 'The birthday is coming',
    'Наступает День рождения ребенка': "The child's birthday is coming",
    'По расписанию': 'On schedule',
    'Цепочка стартует когда...': 'The flow starts when...',
    'Количество запусков': 'Number of starts',
    'Один раз': 'Once',
    'Каждый раз': 'Every time',
    'каждый раз': 'every time',
    'Ветка сработает только один раз на клиента': 'Branch will only be triggered once per customer',
    'Ветка сработает при каждой активации триггера': 'Branch will be triggered each time the event occurs',
    'Если значение совпадает с...': 'If the value is the same as...',
    'Ветки не взаимоисключающие и выполняются параллельно при попадании клиента под несколько условий': 'Branches are not mutually exclusive and are executed in parallel when the customer falls under several conditions',
    'Добавьте условие старта': 'Add a start condition',
    Значение: 'Value',
    '...или добавьте шаг': '...or add a step',
    Ожидание: 'Delay',
    'Добавить поощрение': 'Add reward',
    'Сгорают через': 'Expiring in',
    'Срок действия акции': 'Valid',
    'с момента рассылки': 'since mailing',
    'Товарные рекомендации': 'Product recommendations',
    'Товары со сниженной ценой': 'items which price has dropped',
    'Не забудьте вставить параметр': "Don't forget to to insert the",
    'добавьте хотя бы один канал': 'add at least one channel',
    'Получил рассылку': 'Received the newsletter',
    'Получал рассылку': 'Received the newsletter',
    'Открыл рассылку': 'Opened the newsletter',
    'Достиг цели': 'Achieved the goal',
    Ручную: 'Manual',
    Автоматическую: 'Automated',
    'Участвовал в акции': 'Participated in the action',
    'Применял промокод': 'Applied a promocode',
};
